import { ActionContext } from 'vuex'

import {
	IForm,
	ISubmissionConfiguration,
	IConfigurationOption,
} from '@/models/worklists/outcome'
import { IPlaceOfService } from '@server/api/models/fe/shared'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'

interface IWorklistState {
	form: IForm
	submission: ISubmissionConfiguration
	fields: {
		placesOfService: IPlaceOfService[]
	}
	plans: IPublishedPlanAssociation[]
}

const getInitialState = () => ({
	form: {
		id: '',
		name: '',
	},
	submission: {
		id: '',
		name: '',
	},
	fields: {
		placesOfService: [],
	},
})

const mutations = {
	setForm(state: IWorklistState, form: IForm) {
		state.form = form
	},
	setSubmission(state: IWorklistState, submission: ISubmissionConfiguration) {
		state.submission = submission
	},
	setPlacesOfService(
		state: IWorklistState,
		placesOfService: IPlaceOfService[]
	) {
		state.fields.placesOfService = placesOfService
	},
	setPlans(state: IWorklistState, plans: IPublishedPlanAssociation[]) {
		state.plans = plans
	},
}

const getters = {
	getForm(state: IWorklistState) {
		const form = state.form

		if (form.id && form.name) {
			return form
		}

		return null
	},
	getSubmission(state: IWorklistState) {
		const submission = state.submission

		if (submission.id && submission.name) {
			return submission
		}

		return null
	},
	getPlacesOfService(state: IWorklistState) {
		return state.fields.placesOfService
	},
	getPlans(state: IWorklistState) {
		return state.plans
	},
}

const movePreviousConfigToTop = (
	id: string,
	options: IConfigurationOption[]
) => {
	const optionsCopy = [...options]
	const index = optionsCopy.findIndex(o => o.id === id)
	const option = optionsCopy?.[index] || null

	if (index > -1 && option) {
		optionsCopy.splice(index, 1)
		optionsCopy.unshift(option)
		return optionsCopy
	} else {
		return options
	}
}

const actions = {
	setForm({ commit }: ActionContext<IWorklistState, any>, form: IForm) {
		commit('setForm', form)
		commit('setSubmission', null)
	},
	setSubmission(
		{ commit }: ActionContext<IWorklistState, any>,
		submission: ISubmissionConfiguration
	) {
		commit('setForm', null)
		commit('setSubmission', submission)
	},
	createFormList({ state }: any, options: IForm[]) {
		const form = state.form
		if (form && form.id && form.name) {
			return movePreviousConfigToTop(form.id, options) as IForm[]
		}
		return options
	},
	createConfigurationList({ state }: any, options: ISubmissionConfiguration[]) {
		const submission = state.submission
		if (submission && submission.id && submission.name) {
			return movePreviousConfigToTop(
				submission.id,
				options
			) as ISubmissionConfiguration[]
		}
		return options
	},
	setPlacesOfService(
		{ commit }: ActionContext<IWorklistState, any>,
		placesOfService: string[]
	) {
		commit('setPlacesOfService', placesOfService)
	},
	setPlans(
		{ commit }: ActionContext<IWorklistState, any>,
		plans: IPublishedPlanAssociation[]
	) {
		commit('setPlans', plans)
	},
}

export default {
	namespaced: true,
	state: getInitialState,
	getters,
	mutations,
	actions,
}
