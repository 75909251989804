export default {
	install(Vue: any) {
		const checkFlag = (flag: any, features: string[]): boolean => {
			const flags = features || []
			return !!flags.some((f: any) => f === flag)
		}

		Vue.prototype.$isFeatureOn = function (flag: string) {
			return checkFlag(flag, this.$store.state.environment.features)
		}
	},
}
