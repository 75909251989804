import wizardMachine from './machine'
import { ActionContext } from 'vuex'

import { toast, Alert } from '@/dependencies/sweet-alert'
import { createWorklistLogRocketEvent } from '@/utils/logrocket'

import router from '@/router/index'
import { transition } from '@/store/utils/machine'
import {
	createWorklist as newWorklist,
	fetchPayers,
	fetchProcedures,
	fetchDiagnosisCodes,
} from '@/service/worklist.http'
import store from '@/store/index'
import { IWorklistState } from './state'
import {
	IWorklistCreate,
	WorklistInclude,
} from '../../../server/api/models/fe/worklist'

async function reset({ commit }: ActionContext<IWorklistState, any>) {
	commit('setState', wizardMachine.initial)
	commit('setWorklist', {
		name: '',
		assigneeId: '',
		parameters: {
			include: WorklistInclude.EVERYTHING,
			payers: [],
			procedureCodes: [],
		},
	} as IWorklistCreate)
}

async function createWorklist({
	dispatch,
}: ActionContext<IWorklistState, any>) {
	try {
		await newWorklist(store.state.worklistCreateWizard.worklist)
		toast({ type: Alert.SUCCESS, title: 'Successfully created the worklist' })

		// Track created worklist
		createWorklistLogRocketEvent(store.state.worklistCreateWizard.worklist)

		router.push(`/worklist/list`)
		dispatch('transition', { type: 'SUCCESS' })
	} catch (error) {
		console.warn(error)
		toast({ type: Alert.ERROR, title: error, error })
		dispatch('transition', { type: 'ERROR' })
	}
}

async function getPayers({
	state,
	commit,
}: ActionContext<IWorklistState, any>) {
	if (
		state.payers.currentPage === 0 ||
		state.payers.currentPage < state.payers.totalPages
	) {
		commit('setPayersLoading', true)
		const payerData = await fetchPayers(
			state.payers.currentPage,
			state.payers.query
		)
		commit('setPayers', payerData)
		commit('setPayersLoading', false)
		commit('incrementPayerPage')
	}
}

function resetPayers({ commit }: ActionContext<IWorklistState, any>) {
	commit('resetPayers')
}

async function getProcedures({
	state,
	commit,
}: ActionContext<IWorklistState, any>) {
	if (
		state.procedureCodes.currentPage === 0 ||
		state.procedureCodes.currentPage < state.procedureCodes.totalPages
	) {
		commit('setProceduresLoading', true)
		const procedureData = await fetchProcedures(
			state.procedureCodes.currentPage,
			state.procedureCodes.query,
			state.procedureCodes.categoryId
		)
		commit('setProcedures', procedureData)
		commit('setProceduresLoading', false)
		commit('incrementProcedurePage')
	}
}

function resetProcedures({ commit }: ActionContext<IWorklistState, any>) {
	commit('resetProcedures')
}

function setPayersQuery(
	{ commit }: ActionContext<IWorklistState, any>,
	query: string
) {
	commit('setPayersQuery', query)
}

function setProceduresQuery(
	{ commit }: ActionContext<IWorklistState, any>,
	query: string
) {
	commit('setProceduresQuery', query)
}

function setProceduresCategory(
	{ commit }: ActionContext<IWorklistState, any>,
	categoryId: string
) {
	commit('setProceduresCategory', categoryId)
}

async function setDiagnosisCodes({
	commit,
}: ActionContext<IWorklistState, any>) {
	const codes = await fetchDiagnosisCodes()
	commit('setDiagnosisCodes', codes)
}

export default {
	transition: transition.bind(null, wizardMachine),
	reset,
	createWorklist,
	getPayers,
	getProcedures,
	setPayersQuery,
	setProceduresQuery,
	setProceduresCategory,
	resetPayers,
	resetProcedures,
	setDiagnosisCodes,
}
