import { CopyPasteType } from '../../server/api/models/fe/shared'
import {
	IRequirement,
	IRequirementSet,
} from '../../server/api/models/fe/requirements'

const state: ICopyPasteState = {
	requirement: {} as IRequirement,
	requirementSet: {} as IRequirementSet,
	payerId: null,
	planId: null,
	type: null,
}

interface ICopyPasteState {
	requirement: IRequirement | null
	requirementSet: IRequirementSet | null
	payerId: string | null
	planId: string | null
	type: CopyPasteType | null
}

const mutations = {
	setRequirement(
		copyPasteState: ICopyPasteState,
		{ requirement, payerId, planId }: RequirementPayload
	) {
		copyPasteState.requirement = requirement
		copyPasteState.payerId = payerId
		copyPasteState.planId = planId
		copyPasteState.type = CopyPasteType.REQUIREMENT
	},
	setRequirementSet(
		copyPasteState: ICopyPasteState,
		{ requirementSet, payerId, planId }: RequirementSetPayload
	) {
		copyPasteState.requirementSet = requirementSet
		copyPasteState.payerId = payerId
		copyPasteState.planId = planId
		copyPasteState.type = CopyPasteType.REQUIREMENT_SET
	},
}

export default {
	namespaced: true,
	state,
	mutations,
}

interface RequirementSetPayload {
	requirementSet: IRequirementSet
	payerId: string
	planId: string
}
interface RequirementPayload {
	requirement: IRequirement
	payerId: string
	planId: string
}
