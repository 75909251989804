import { IPermission } from '@myndshft/types-mc-api'

export interface IAppUser {
	email: string
	expiresAt: number
	id: string
	imageUrl: string
	name: string
	firstName: string
	lastName: string
	tokenId: string
	permissions: IPermission[]
}

export enum Permissions {
	BANNER_EDIT = 'MC-BannerEdit',
	BANNER_DELETE = 'MC-BannerDelete',
	CLIENT_DETAIL_DELETE = 'MC-ClientDetailDelete',
	CLIENT_DELETE = 'MC-ClientDelete',
	CLIENT_DETAIL_EDIT = 'MC-ClientDetailEdit',
	EVERYONE = 'MC-Everyone',
	IDP_USER_DETAIL_EDIT = 'MC-IDPUserDetailEdit',
	PAYER_DETAIL_DELETE = 'MC-PayerDetailDelete',
	PAYER_DETAIL_EDIT = 'MC-PayerDetailEdit',
	PAYER_EDIT = 'MC-PayerEdit',
	REFERENCE_RATE_DELETE = 'MC-ReferenceRateDelete',
	REFERENCE_RATE_EDIT = 'MC-ReferenceRateEdit',
	TENANT_DETAIL_EDIT = 'MC-TenantDetailEdit',
	TENANT_DETAIL_DELETE = 'MC-TenantDetailDelete',
	USERS_EDIT = 'MC-UsersEdit',
	WORKLIST_EDIT = 'MC-WorklistEdit',
	WORKLIST_DELETE = 'MC-WorklistDelete',
	WORKLIST_DETAIL_DELETE = 'MC-WorklistDetailDelete',
	WORKLIST_DETAIL_EDIT = 'MC-WorklistDetailEdit',
	WORKLIST_PUBLISH = 'MC-WorklistPublish',
}

export interface IAppUserState {
	user: IAppUser | null
	isPendingLogout: boolean
	clientId: string | null
}

export interface IGoogleUserResponse {
	clientId: string
	credential: string
	select_by: string
}
export interface IGoogleuserResponseJWT {
	aud: string
	azp: string
	email: string
	email_verified: boolean
	exp: number
	family_name: string
	given_name: string
	hd: string
	iat: number
	iss: string
	jti: string
	name: string
	nbf: number
	picture: string
	sub: string
}
