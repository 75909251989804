
import {
	defineComponent,
	reactive,
	onMounted,
	toRefs,
	watchEffect,
	ref,
} from 'vue'
import fetchEnvironment, {
	fetchFeatures,
	fetchBuild,
} from '@/service/environment.http'

import LogoutControl from '@/components/auth/logout-control.vue'
import Version from '@/components/version.vue'

import { useAuth } from './features/auth'
import { initLogRocket } from '@/utils/logrocket'
import { useStore, useRouter } from '@/utils/composables'

export default defineComponent({
	components: {
		LogoutControl,
		Version,
	},
	setup() {
		const store = useStore()
		const router = useRouter()
		const layout = ref('')
		const event: any = reactive({
			gapiReady: false,
			version: store.state.environment.build,
		})
		const { checkExpiration } = useAuth()

		watchEffect(() => {
			layout.value = `${router.app.$route.meta?.layout || 'default'}-layout`
		})

		onMounted(async () => {
			try {
				const environment = await fetchEnvironment()
				store.commit('environment/load', environment)

				injectGoogleScript()

				initLogRocket(environment.environment)
			} catch (error) {
				console.warn('Failed to fetch environment')
			}
			try {
				const flags = await fetchFeatures()
				store.commit('environment/features', flags)
				const build = await fetchBuild()
				store.commit('environment/build', build)
			} catch {
				console.warn('Failed to fetch feature flags and/or build')
			}
			checkExpiration()
			store.dispatch('worklistCreateWizard/setDiagnosisCodes')
		})

		const injectGoogleScript = () => {
			const scriptTag = document.createElement('script')
			scriptTag.setAttribute('src', 'https://accounts.google.com/gsi/client')
			scriptTag.setAttribute('type', 'text/javascript')
			scriptTag.addEventListener('load', () => {
				event.gapiReady = true
			})

			document.body.appendChild(scriptTag)
		}

		return {
			layout,
			...toRefs(event),
		}
	},
})
