import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import dayjs from 'dayjs'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		meta: { layout: 'default' },
		component: () => import('@/views/index.vue'),
	},
	{
		path: '/payer/list',
		name: 'payer-list',
		meta: { layout: 'two-column' },
		component: () => import('@/views/payer/list.vue'),
	},
	{
		path: '/payer/create',
		name: 'payer-create',
		meta: { layout: 'two-column' },
		component: () => import('@/views/payer/wizard.vue'),
	},
	{
		path: '/payer/:id',
		name: 'payer-id',
		meta: { layout: 'two-column' },
		component: () => import('@/views/payer/id.vue'),
	},
	{
		path: '/client/list',
		name: 'client-list',
		meta: { layout: 'two-column' },
		component: () => import('@/views/client/list.vue'),
	},
	{
		path: '/client/create',
		name: 'client-create',
		meta: { layout: 'two-column' },
		component: () => import('@/views/client/wizard.vue'),
	},
	{
		path: '/client/:id',
		name: 'client-id',
		meta: { layout: 'two-column' },
		component: () => import('@/views/client/id.vue'),
	},
	{
		path: '/user/:id',
		name: 'user-id',
		meta: { layout: 'two-column' },
		component: () => import('@/views/user/id.vue'),
	},
	{
		path: '/worklist/list',
		name: 'worklist-manager',
		meta: { layout: 'two-column' },
		component: () => import('@/views/worklist/list.vue'),
	},
	{
		path: '/worklist/create',
		name: 'worklist-create',
		meta: { layout: 'two-column' },
		component: () => import('@/views/worklist/create-wizard.vue'),
	},
	{
		path: '/worklist/upload',
		name: 'worklist-upload',
		meta: { layout: 'two-column' },
		component: () => import('@/views/worklist/upload-wizard.vue'),
	},
	{
		path: '/worklist/:id',
		name: 'worklist-id',
		meta: { layout: 'two-column' },
		component: () => import('@/views/worklist/id.vue'),
	},
	{
		path: '/user-management/list',
		name: 'user-management-list',
		meta: { layout: 'two-column' },
		component: () => import('@/views/user-management/list.vue'),
	},
	{
		path: '/notification/list',
		name: 'notification-banner-list',
		meta: { layout: 'two-column' },
		component: () => import('@/views/notification-banner/view.vue'),
	},
	{ path: '*', redirect: '/' },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Auth Guard
router.beforeEach((to, _, next) => {
	const loginStore = store
	const user = loginStore.state.appUser.user
	function goHome() {
		const homePath = '/'
		// Only navigate to home path if not already on home path
		if (homePath !== to.path) {
			next('/')
		} else {
			next()
		}
	}
	if (user) {
		if (dayjs(user.expiresAt).diff(dayjs(), 'second', true) <= 0) {
			goHome()
		} else {
			next()
		}
	} else {
		goHome()
	}
})

export default router
