import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import appUser from './appUser'
import { environment } from './environment'
import payer from './payer'
import tenant from './tenant'
import client from './client'
import cpts from './cpts'
import roles from './roles'
import clientWizard from './client-wizard/index'
import payerWizard from './payer-wizard/index'
import tenantWizard from './tenant-wizard/index'
import worklistCreateWizard from './worklist-create-wizard/index'
import worklistUploadWizard from './worklist-upload-wizard/index'
import serviceTypes from './service-types'
import copyPaste from './copy-paste'
import worklist from './worklist'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		appUser,
		environment,
		payer,
		tenant,
		client,
		cpts,
		roles,
		clientWizard,
		payerWizard,
		tenantWizard,
		worklistCreateWizard,
		worklistUploadWizard,
		serviceTypes,
		copyPaste,
		worklist,
	},
	plugins: [
		createPersistedState({
			paths: [
				'client',
				'payer',
				'tenant',
				'appUser',
				'cpts',
				'roles',
				'environment',
				'worklist',
			],
			storage: window.sessionStorage,
		}),
	],
})
