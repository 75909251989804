import { getCurrentInstance } from 'vue'

// Accessing the Vuex store instance
export function useStore() {
	const vm = getCurrentInstance()?.proxy

	if (!vm) {
		throw new Error('instance not available for store')
	}

	return vm.$store
}

// Accessing the vue-router router instance
export function useRouter() {
	const vm = getCurrentInstance()?.proxy

	if (!vm) {
		throw new Error('instance not available for router')
	}

	return vm.$router
}

// Accessing the vue-router route instance
export function useRoute() {
	const vm = getCurrentInstance()?.proxy

	if (!vm) {
		throw new Error('instance not available for route')
	}

	return vm.$route
}

// Used to access vue prototype permission methods
export function usePermission(): any {
	const vm = getCurrentInstance()?.proxy

	if (!vm) {
		throw new Error('instance not available for permission')
	}

	return vm
}
