import {
	ITenant,
	ITenantListResults,
	ITenantSetting,
} from '../../server/api/models/fe/tenant'
import { getBff } from '@/service/shared.http'
import axios from 'axios'
import { TenantUniqueID } from '@myndshft/types-mc-api'
import { IClientDetails } from '@server/api/models/fe/client'
import { IUserDetails } from '@server/api/models/fe/user'

export async function fetchTenantList(): Promise<ITenantListResults> {
	const path = `${getBff()}/tenants`
	const response = await axios.get<any>(path)
	const tenants = response.data.tenants

	return {
		tenants,
	}
}

export async function fetchTenant(id: TenantUniqueID): Promise<ITenant> {
	const path = `${getBff()}/tenant/${id}`
	const response = await axios.get<ITenant>(path)
	return response.data
}

export async function fetchTenantClients(
	id: TenantUniqueID
): Promise<IClientDetails[]> {
	const path = `${getBff()}/tenant/${id}/clients`
	const response = await axios.get<IClientDetails[]>(path)
	return response.data
}

export async function fetchTenantUsersPage(
	id: TenantUniqueID,
	pageToken?: string
): Promise<ITenant> {
	const path = `${getBff()}/users?tenant_id=${id}&page_token=${pageToken}`
	const response = await axios.get(path)
	return response.data
}

export async function fetchAllTenantUsers(
	id: TenantUniqueID | string
): Promise<IUserDetails[]> {
	const path = `${getBff()}/tenant/${id}/users`
	const response = await axios.get(path)
	return response.data
}

export async function createTenant(tenant: ITenant): Promise<TenantUniqueID> {
	const path = `${getBff()}/tenant`
	const response = await axios.post(path, tenant)
	return response.data.id
}

export async function updateTenant(tenant: ITenant): Promise<TenantUniqueID> {
	const path = `${getBff()}/tenant`
	const response = await axios.put(path, tenant)
	return response.data.id
}

export async function updateSetting(
	tenantId: TenantUniqueID,
	setting: ITenantSetting
): Promise<void> {
	const path = `${getBff()}/tenant/${tenantId}/settings`
	await axios.put(path, setting)
}

export async function fetchTenantSettings(
	id: TenantUniqueID
): Promise<ITenantSetting[]> {
	const path = `${getBff()}/tenant/${id}/settings`
	const response = await axios.get<ITenantSetting[]>(path)
	return response.data
}

export async function resetTenantSettings(tenantId: TenantUniqueID) {
	const path = `${getBff()}/tenant/${tenantId}/settings`
	await axios.delete(path)
}
