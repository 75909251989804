import { Machine, MachineConfig } from 'xstate'
import { MachineEvent } from '@/store/utils/machine'

export interface ClientWizardSchema {
	states: {
		reset: object
		wizard: {
			states: {
				steps: {
					states: {
						clientInformation: object
						duplication: {
							states: {
								checking: object
								confirmation: object
							}
						}
						transactions: object
						confirmation: object
						creatingClient: object
						success: object
					}
				}
				discard: {
					states: {
						idle: object
						confirmation: object
					}
				}
			}
		}
	}
}

const machineConfig: MachineConfig<any, ClientWizardSchema, MachineEvent> = {
	id: 'ClientWizard',
	strict: true,
	initial: 'reset',
	states: {
		reset: {
			id: 'reset',
			entry: ['reset'],
			on: {
				'': 'wizard',
			},
		},
		wizard: {
			type: 'parallel',
			states: {
				steps: {
					initial: 'clientInformation',
					states: {
						clientInformation: {
							id: 'clientInformation',
							on: {
								NEXT: 'duplication.checking',
							},
						},
						duplication: {
							initial: 'checking',
							states: {
								checking: {
									entry: ['checkDuplicates'],
									on: {
										FOUND_POTENTIAL_DUPLICATES: 'confirmation',
										NO_DUPLICATES: '#transactions',
									},
								},
								confirmation: {
									on: {
										PREV: '#clientInformation',
										NEXT: '#transactions',
									},
								},
							},
						},
						transactions: {
							id: 'transactions',
							on: {
								PREV: 'clientInformation',
								NEXT: 'confirmation',
							},
						},
						confirmation: {
							on: {
								PREV: 'transactions',
								NEXT: 'creatingClient',
							},
						},
						creatingClient: {
							entry: ['createClient'],
							on: {
								ERROR: 'confirmation',
								SUCCESS: '#reset',
							},
						},
						success: {},
					},
				},
				discard: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								LEAVE: 'confirmation',
							},
						},
						confirmation: {
							on: {
								CANCEL: 'idle',
								DISCARD: '#reset',
							},
						},
					},
				},
			},
		},
	},
}

export default Machine(machineConfig)
