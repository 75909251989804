import { State } from 'xstate'
import { MachineEvent } from '../utils/machine'
import { WorklistWizardSchema } from './machine'
import { IWorklistState } from './state'
import {
	IPayerData,
	IProcedureCodeData,
	IWorklistCreate,
	IWorklistPayer,
	IWorklistProcedureCode,
	WorklistInclude,
} from '../../../server/api/models/fe/worklist'
import { IPayerPlan } from 'server/api/models/fe/payer'
import { IDiagnosisSectionDisplay } from '@server/api/models/fe/shared'

function setState(
	state: any,
	newState: State<any, MachineEvent, WorklistWizardSchema>
) {
	state.state = newState.value
}

function setWorklist(state: any, worklist: IWorklistCreate) {
	state.worklist = worklist
}

function setPayers(
	state: IWorklistState,
	{ payers, totalPages, total }: IPayerData
) {
	state.payers.payers.push(...payers)
	state.payers.totalPages = totalPages
	state.payers.total = total
}

function setPayersLoading(state: IWorklistState, isLoading: boolean) {
	state.payers.isLoading = isLoading
}

function incrementPayerPage(state: IWorklistState) {
	state.payers.currentPage++
}

function resetPayers(state: IWorklistState) {
	state.payers.payers = []
	state.payers.currentPage = 0
	state.payers.totalPages = 0
	state.payers.totalPages = 0
}

function setProcedures(
	state: IWorklistState,
	{ procedureCodes, totalPages, total }: IProcedureCodeData
) {
	state.procedureCodes.procedureCodes.push(...procedureCodes)
	state.procedureCodes.totalPages = totalPages
	state.procedureCodes.total = total
}

function setProceduresLoading(state: IWorklistState, isLoading: boolean) {
	state.procedureCodes.isLoading = isLoading
}

function incrementProcedurePage(state: IWorklistState) {
	state.procedureCodes.currentPage++
}

function resetProcedures(state: IWorklistState) {
	state.procedureCodes.procedureCodes = []
	state.procedureCodes.currentPage = 0
	state.procedureCodes.totalPages = 0
	state.procedureCodes.totalPages = 0
}

function addParameterPayer(state: IWorklistState, payer: IWorklistPayer) {
	state.worklist.parameters.payers.push(payer)
}

function removeParameterPayer(state: IWorklistState, index: number) {
	state.worklist.parameters.payers.splice(index, 1)[0]
}

function addParameterPlan(
	state: IWorklistState,
	{ payerId, plan }: { payerId: string; plan: IPayerPlan }
) {
	const payer = state.worklist.parameters.payers.find(p => p.id === payerId)
	payer?.plans.push(plan)
}

function removeParameterPlan(
	state: IWorklistState,
	{ payerId, plan }: { payerId: string; plan: IPayerPlan }
) {
	const payer = state.worklist.parameters.payers.find(p => p.id === payerId)
	const index =
		payer?.plans.findIndex(payerPlan => payerPlan.id === plan.id) ?? -1
	if (index >= 0) {
		payer?.plans.splice(index, 1)
	}
}

function addParameterProcedure(
	state: IWorklistState,
	code: IWorklistProcedureCode
) {
	state.worklist.parameters.procedureCodes.push(code)
}

function removeParameterProcedure(state: IWorklistState, index: number) {
	state.worklist.parameters.procedureCodes.splice(index, 1)[0]
}

function setName(state: IWorklistState, name: string) {
	state.worklist.name = name
}

function setInclude(state: IWorklistState, include: WorklistInclude) {
	state.worklist.parameters.include = include
}

function setPayersQuery(state: IWorklistState, query: string) {
	state.payers.query = query
}

function setProceduresQuery(state: IWorklistState, query: string) {
	state.procedureCodes.query = query
}

function setProceduresCategory(state: IWorklistState, categoryId: string) {
	state.procedureCodes.categoryId = categoryId
}

function setDiagnosisCodes(
	state: IWorklistState,
	codes: IDiagnosisSectionDisplay[]
) {
	state.diagnosisCodes = codes
}

export default {
	setState,
	setWorklist,
	setPayers,
	setPayersQuery,
	setPayersLoading,
	incrementPayerPage,
	resetPayers,
	setProcedures,
	setProceduresQuery,
	setProceduresCategory,
	setProceduresLoading,
	incrementProcedurePage,
	resetProcedures,
	addParameterPayer,
	removeParameterPayer,
	addParameterPlan,
	removeParameterPlan,
	addParameterProcedure,
	removeParameterProcedure,
	setName,
	setInclude,
	setDiagnosisCodes,
}
