import axios from 'axios'
import queryString from 'query-string'

import interceptor from '@/service/interceptor'
import {
	IClientDetails,
	IClientPayer,
	IClientPayerCPT,
	IClientPayerTransactionsResponse,
	IClientUser,
	IInboundFax,
} from '../../server/api/models/fe/client'
import {
	IClient,
	IClientListResults,
	IClientPayerMC,
	IClientPayers,
} from '../../server/api/models/fe/client'
import { IClientQueryParams } from '@/models/client/client'

import { getApiServerV2, getBff } from './shared.http'
import { ClientUniqueID } from '@myndshft/types-mc-api'
import { ICPT } from '../../server/api/models/fe/shared'

// Assign interceptor to add request headers
axios.interceptors.request.use(interceptor as any)

export async function fetchClientList(
	queryOptions: IClientQueryParams
): Promise<IClientListResults> {
	const qs = queryString.stringify(
		{
			per_page: queryOptions.perPage,
			page: Number(queryOptions.page) - 1,
			name: queryOptions.type === 'name' ? queryOptions.query : '',
			last_name: queryOptions.type === 'lastName' ? queryOptions.query : '',
			status: queryOptions.status,
		},
		{ skipEmptyString: true }
	)

	const path = `${getBff()}/clients?${qs}`
	const response = await axios.get<any>(path)
	const total = response.data.total ?? 0
	const totalPages = response.data.totalPages ?? 0
	const clients = response.data.clients ?? []
	const headers = response.data.headers

	return {
		clients,
		total,
		totalPages,
		headers,
	}
}

export async function fetchAllClients(): Promise<IClientDetails[]> {
	const path = `${getBff()}/clients/all`
	const response = await axios.get<any>(path)
	return response.data
}

export async function fetchClient(ID: string): Promise<IClient> {
	const path = `${getBff()}/client/${ID}`
	const response = await axios.get<IClient>(path)
	return response.data
}

export async function fetchClientPayers(
	clientId: string
): Promise<IClientPayers> {
	const path = `${getBff()}/client/${clientId}/payers`
	const response = await axios.get<IClientPayers>(path)
	return response.data
}

export async function fetchClientPayer(
	clientId: string,
	payerId: string
): Promise<IClientPayer> {
	const path = `${getBff()}/client/${clientId}/payer/${payerId}`
	const response = await axios.get<IClientPayer>(path)
	return response.data
}

export async function fetchClientPayerTransactions(
	clientId: string,
	payerId: string
): Promise<IClientPayerTransactionsResponse> {
	const path = `${getBff()}/client/${clientId}/payer/${payerId}/transactions`
	const response = await axios.get<IClientPayerTransactionsResponse>(path)
	return response.data
}

export async function createClient(client: IClient): Promise<void> {
	const path = `${getBff()}/clients`
	return await axios.post(path, client)
}

export async function updateClient(client: IClient): Promise<void> {
	const path = `${getBff()}/client/${client.client.id}`
	await axios.put(path, client)
}

export async function updateClientPayer(
	clientPayer: IClientPayerMC | IClientPayer,
	clientID: ClientUniqueID
) {
	const path = `${getBff()}/client/${clientID}/payer/${clientPayer.payerId}`
	await axios.put(path, clientPayer)
}

export async function createClientCpt(
	cpt: ICPT,
	clientID: ClientUniqueID
): Promise<void> {
	const procedure = { procedure: cpt.code }
	const path = `${getBff()}/client/${clientID}/procedures`
	await axios.post(path, procedure)
}

export async function deleteClientProcedure(
	cpt: ICPT,
	clientID: ClientUniqueID
): Promise<string> {
	const path = `${getBff()}/client/${clientID}/procedures/${cpt.code}`
	let message = ''
	await axios
		.delete(path)
		.then(() => {
			message = 'Successfully Deleted Client CPT'
		})
		.catch(err => {
			if (err.response.status === 409) {
				message =
					'Procedure code associated with Client Payer Pricing and may not be deleted'
			} else {
				message = 'Unknown error occurred deleting procedure code.'
			}
		})
	return message
}

export async function updateClientCptPricing(
	id: string,
	data: any
): Promise<void> {
	const path = `${getApiServerV2()}/clients/${id}/pricing`
	const headers = {
		'Content-Type': 'multipart/form-data',
	}
	return await axios.post(path, data, { headers })
}

export async function fetchClientPayerPricing(
	clientId: string,
	payerId: string
): Promise<IClientPayerCPT[]> {
	const path = `${getBff()}/client/${clientId}/payers/${payerId}/pricing`
	const response = await axios.get<IClientPayerCPT[]>(path)
	return response.data
}

export async function createOrUpdateClientPayerCpt(
	clientId: string,
	payerId: string,
	procedureCode: string,
	cpt: any
): Promise<void> {
	const path = `${getBff()}/client/${clientId}/payers/${payerId}/pricing/${procedureCode}`
	await axios.put(path, cpt)
}

export async function deleteClientPayerCpt(
	clientId: string,
	payerId: string,
	procedureCode: string
): Promise<void> {
	const path = `${getBff()}/client/${clientId}/payers/${payerId}/pricing/${procedureCode}`
	await axios.delete(path)
}

export async function updateInboundFax(
	client: IClientDetails,
	fax: IInboundFax
): Promise<void> {
	const path = `${getBff()}/clients/${client.id}/inboundFax`
	await axios.post(path, { client, fax })
}

export async function updateClientUsers(
	clientId: string,
	userIds: string[]
): Promise<void> {
	const path = `${getBff()}/clients/${clientId}/users`
	await axios.patch(path, userIds)
}

export async function fetchClientUsers(
	clientId: string
): Promise<IClientUser[]> {
	const path = `${getBff()}/clients/${clientId}/users`
	const response = await axios.get(path)
	return response.data
}

export async function deleteClient(clientId: string) {
	const path = `${getBff()}/clients/${clientId}`
	const response = await axios.delete(path)
	return response.data
}

export async function removeClientUserAssociations(
	clientId: string,
	userIds: string[]
) {
	const path = `${getBff()}/clients/${clientId}/users`
	const response = await axios.delete(path, { data: userIds })
	return response.data
}

export async function bulkUpdateClients(
	updateMask: string[],
	clientIds: string[],
	status: string
) {
	const path = `${getBff()}/clients`
	const response = await axios.patch(path, { updateMask, clientIds, status })
	return response.data
}
