import axios from 'axios'
import { getBff } from './shared.http'

export default async function fetchEnvironment() {
	const path = `${getBff()}/config`
	const response = await axios.get(path)
	return response.data
}

export async function fetchFeatures() {
	const path = `${getBff()}/features`
	const response = await axios.get<any>(path)
	return response.data
}

export async function fetchBuild() {
	const path = `${getBff()}/build`
	const response = await axios.get<any>(path)
	return response.data.MYNDCONTROL_BUILD_NUMBER || ''
}
