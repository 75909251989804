import Vue from 'vue'

interface ToastParams {
	type?: Alert
	title: any
	error?: any
	timer?: number
	showConfirmButton?: boolean
	showCancelButton?: boolean
	confirmButtonText?: string
	confirmButtonColor?: string
	html?: HTMLElement | string
	width?: string
	customClass?: {
		container: string
	}
}

export enum Alert {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
}

export function getTitle() {
	return Vue.swal.getTitle()
}

export function closeSwal() {
	return Vue.swal.close()
}

export function toast(params: ToastParams, isCustom = false) {
	const sweetAlert = Vue.swal
	let errorDescription: string

	const {
		type,
		confirmButtonText,
		error,
		showConfirmButton = false,
		html,
		timer = 3000,
		width,
	} = params

	let { title } = params
	let mixin = {}

	if (!isCustom) {
		mixin = {
			position: 'bottom-end',
			showConfirmButton,
			toast: true,
			html,
			timer,
			width,
		}
	} else {
		mixin = params
	}

	const toastConfiguration = sweetAlert.mixin(mixin)

	// Error detail
	if (type === Alert.ERROR && error?.response) {
		errorDescription = error.response.status
			? error.response.status.toString()
			: 'Network Error'
		title = `${errorDescription}: ${title}`
	}

	return toastConfiguration.fire({
		title,
		confirmButtonText,
		icon: type,
	})
}
