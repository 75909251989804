import wizardMachine, { WorklistWizardStep } from './machine'
import { NULL_EVENT } from '@/store/utils/machine'

import { IWorklistState } from './state'

function wizardStep(state: any): number {
	const steps = {
		0: [WorklistWizardStep.STEP_ONE],
		1: [WorklistWizardStep.STEP_TWO],
		2: [WorklistWizardStep.STEP_THREE],
		3: [
			WorklistWizardStep.CONFIRMATION,
			WorklistWizardStep.CREATING_WORKLIST,
			WorklistWizardStep.SUCCESS,
		],
	}
	const currentState = wizardMachine.transition(state.state, NULL_EVENT)
	for (const [step, states] of Object.entries(steps)) {
		const or = (p: boolean, q: boolean): boolean => p || q
		const doesMatch = states
			.map(s => currentState.matches(`wizard.steps.${s}`))
			.reduce(or, false)

		if (doesMatch) {
			return Number(step)
		}
	}

	return -1
}

function getPayers(state: IWorklistState) {
	return state.payers.payers
}

function getParameterPayers(state: IWorklistState) {
	return state.worklist.parameters.payers
}

function getParameterProcedures(state: IWorklistState) {
	return state.worklist.parameters.procedureCodes
}

function getDiagnosisCodes(state: IWorklistState) {
	return state.diagnosisCodes
}

export default {
	wizardStep,
	getPayers,
	getParameterPayers,
	getParameterProcedures,
	getDiagnosisCodes,
}
