import { render, staticRenderFns } from "./version.vue?vue&type=template&id=2803ded5&scoped=true&functional=true"
var script = {}
import style0 from "./version.vue?vue&type=style&index=0&id=2803ded5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "2803ded5",
  null
  
)

export default component.exports