import { fetchCPTList } from '@/service/shared.http'
import { ICPT } from '../../server/api/models/fe/shared'

interface ICPTState {
	all: ICPT[]
}
const state: ICPTState = {
	all: [],
}

const getters = {
	getCount(cptState: ICPTState) {
		return cptState.all.length
	},
}

const mutations = {
	setAll(cptState: ICPTState, cpts: ICPT[]) {
		cptState.all = cpts
	},
}

const actions = {
	async fetchCPTs({ commit }: any, code?: string): Promise<void> {
		const cpts = await fetchCPTList(code ? code : undefined)
		commit('setAll', cpts)
	},
	resetCPTs({ commit }: any): void {
		commit('setAll', [])
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
