import store from '@/store'
import {
	IResponsePhoneMethod,
	ContactMethodType,
	IResponseFaxMethod,
	IResponsePortalMethod,
	IResponsePayersGET,
	IResponsePlan,
} from '@myndshft/types-mc-api'
import {
	fromResponsePhones,
	fromResponseFaxes,
	fromResponsePortals,
	fromResponseOutcome,
} from '../../../../server/api/models/mappers/from-server/payer'
import {
	IContactPayerFax,
	IContactPayerPhone,
	IContactPayerPortal,
	IPayerListItem,
	IPayerPlan,
	IPriorAuthRecord,
	IPriorAuthRecords,
} from '../../../../server/api/models/fe/payer'
import {
	IResponseContactMethods,
	IResponseContacts,
	IResponsePayerProcedureCode,
	IResponsePayerProcedureCodes,
} from '@/models/server-models/response-models/v2/payer'

export function fromResponseContactMethods(
	response: IResponseContactMethods
): IResponseContacts {
	const methods: any[] = response.contact_methods
	const phones = methods.filter(
		(method: IResponsePhoneMethod) => method.type === ContactMethodType.PHONE
	)
	const faxes = methods.filter(
		(method: IResponseFaxMethod) => method.type === ContactMethodType.FAX
	)
	const portals = methods.filter(
		(method: IResponsePortalMethod) => method.type === ContactMethodType.PORTAL
	)
	const plans = store.state.payer.payerPlans

	return {
		phones: phones
			.map(fromResponsePhones)
			.map(phone => fromResponseContactPlans(phone, plans)),
		faxes: faxes
			.map(fax => fromResponseFaxes(fax))
			.map(fax => fromResponseContactPlans(fax, plans)),
		portals: portals
			.map(fromResponsePortals)
			.map(portal => fromResponseContactPlans(portal, plans)),
	}
}

export function fromMCResponsePayerListResponse(
	payerList: IResponsePayersGET
): IPayerListItem[] {
	return payerList.payers.map(payer => {
		return {
			id: payer.id,
			uniqueId: payer.unique_id,
			name: payer.name,
			status: payer.status,
		}
	})
}

export function fromResponsePlan(plan: IResponsePlan): IPayerPlan {
	return {
		id: plan.id,
		uniqueId: plan.unique_id,
		name: plan.name,
	}
}

export function fromPayerPriorAuthRecords(
	request: IResponsePayerProcedureCodes
): IPriorAuthRecords {
	return {
		total: request.total,
		totalPages: request.total_pages,
		items: request.items.map(fromPayerPriorAuthRecord),
	}
}

function fromResponseContactPlans(
	method: IContactPayerPhone | IContactPayerFax | IContactPayerPortal,
	payerPlans: IPayerPlan[]
): any {
	const plan = payerPlans.find(payerPlan => payerPlan.id === method.plan.id)

	if (plan) {
		method.plan = plan
	}
	return method
}

export function fromPayerPriorAuthRecord(
	procedure: IResponsePayerProcedureCode
): IPriorAuthRecord {
	return {
		active: procedure.active,
		id: procedure.id,
		plan: procedure.plan.type,
		procedureCategory: procedure.procedure_category,
		procedureCode: procedure.procedure_code,
		outcome: fromResponseOutcome(procedure.outcome),
		requirementSetId: fromPayerPriorAuthRecordRequirementSet(
			procedure.requirement_set
		),
		meta: procedure._meta
			? {
					lastModified: procedure._meta.last_modified,
					lastModifiedBy: procedure._meta.last_modified_by,
			  }
			: null,
	}
}

function fromPayerPriorAuthRecordRequirementSet(set: { id?: string } = {}) {
	return set?.id || ''
}
