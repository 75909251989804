import axios from 'axios'

import { ClientUniqueID, UserUniqueID } from '@myndshft/types-mc-api'
import { IUser, IUserDetails } from '../../server/api/models/fe/user'
import { IUserRole } from 'server/api/models/fe/role'
import { getBff } from '@/service/shared.http'

export async function fetchUser(id: UserUniqueID): Promise<IUser> {
	const path = `${getBff()}/user/${id}`
	const response = await axios.get<IUser>(path)
	return response.data
}

export async function createUser(
	user: IUser,
	roles: string[]
): Promise<UserUniqueID> {
	const path = `${getBff()}/user`
	const response = await axios.post(path, { user, roles })
	return response.data.id
}

export async function updateUserDetails(
	userId: string,
	user: Partial<IUserDetails>,
	updateMask: string[]
): Promise<IUserDetails> {
	const path = `${getBff()}/user/${userId}`
	const response = await axios.patch(path, { user, updateMask })
	return response.data
}

export async function fetchUserRoles(): Promise<IUserRole[]> {
	const path = `${getBff()}/roles`
	const response = await axios.get(path)
	return response.data
}

export async function addUserToClient(
	userId: UserUniqueID,
	clientIds: ClientUniqueID[]
): Promise<void> {
	const path = `${getBff()}/user/${userId}/client`
	await axios.post(path, { clientIds })
}

export async function removeUserFromClient(
	userId: UserUniqueID,
	clientId: ClientUniqueID
): Promise<void> {
	const path = `${getBff()}/user/${userId}/client/${clientId}`
	await axios.delete(path)
}
