import wizardMachine from './machine'
import { NULL_EVENT } from '@/store/utils/machine'

function wizardStep(state: any): number {
	// Map FSM states to wizard steps.
	const steps = {
		0: ['wizard.steps.clientInformation', 'wizard.steps.duplication'],
		1: ['wizard.steps.transactions'],
		2: [
			'wizard.steps.confirmation',
			'wizard.steps.creatingClient',
			'wizard.steps.success',
		],
	}

	const currentState = wizardMachine.transition(state.state, NULL_EVENT)
	for (const [step, states] of Object.entries(steps)) {
		const or = (p: boolean, q: boolean): boolean => p || q
		const doesMatch = states.map(s => currentState.matches(s)).reduce(or, false)

		if (doesMatch) {
			return Number(step)
		}
	}

	return -1
}

export default {
	wizardStep,
}
