import { ActionContext } from 'vuex'
import stringSimilarity from 'string-similarity'

import wizardMachine from './machine'
import { transition } from '@/store/utils/machine'
import router from '@/router/index'
import { fetchAllClients } from '@/service/client.http'
import { toast, Alert } from '@/dependencies/sweet-alert'
import { sortByProjection } from '@/components/table/features/sortable'

async function reset({ commit, dispatch }: ActionContext<any, any>) {
	dispatch('client/reset')
	commit('setState', wizardMachine.initial)
	commit('setClientDuplicates', [])
}

async function checkDuplicates({
	commit,
	dispatch,
	state,
}: ActionContext<any, any>) {
	try {
		const clients = await fetchAllClients()
		const similarClients = clients
			.map(client => {
				return {
					...client,
					coefficient: stringSimilarity.compareTwoStrings(
						`${state.client.client?.name || ''} ${
							state.client?.firstName || ''
						} ${state.client?.lastName || ''}`.toLowerCase(),
						`${client?.name || ''} ${client?.firstName || ''} ${
							client?.lastName || ''
						}`.toLowerCase()
					),
				}
			})
			.filter(client => client.coefficient > 0.5)
			.sort(sortByProjection('coefficient'))
			.reverse()

		if (similarClients.length > 0) {
			commit('setClientDuplicates', similarClients)
			dispatch('transition', { type: 'FOUND_POTENTIAL_DUPLICATES' })
		} else {
			dispatch('transition', { type: 'NO_DUPLICATES' })
		}
	} catch (error) {
		console.warn(error)
	}
}

async function createClient({ dispatch, state }: ActionContext<any, any>) {
	const creatingTimeout = setTimeout(() => {
		toast({
			type: Alert.INFO,
			title: 'Still attempting to create client. Please wait.',
		})
	}, 5000)
	try {
		await dispatch('client/createClient', { config: state.client })
		toast({ type: Alert.SUCCESS, title: 'Successfully created client' })
		router.push(`/client/${state.client.client.id}`)
		dispatch('transition', { type: 'SUCCESS' })
	} catch (error: any) {
		console.warn(error)
		const message =
			error.error.message || 'Please check the client data for errors.'
		// Show specific error and capitalize first letter of the error message
		toast({
			type: Alert.ERROR,
			title: `Failed to create client. ${message.replace(
				/^./,
				message[0].toUpperCase()
			)}`,
			timer: 20000,
		})

		dispatch('transition', { type: 'ERROR' })
	} finally {
		clearTimeout(creatingTimeout)
	}
}

export default {
	transition: transition.bind(null, wizardMachine),
	reset,
	checkDuplicates,
	createClient,
}
