import {
	IWorklistCreate,
	IWorklistPayer,
	IWorklistProcedureCode,
	WorklistInclude,
} from '../../../server/api/models/fe/worklist'
import wizardMachine from './machine'

export interface IWorklistState {
	state: any
	worklist: IWorklistCreate
	payers: {
		payers: IWorklistPayer[]
		total: number
		totalPages: number
		query: string
		isLoading: boolean
		currentPage: number
	}
	procedureCodes: {
		procedureCodes: IWorklistProcedureCode[]
	}
}

const state = (): IWorklistState => ({
	state: wizardMachine.initial,
	worklist: {
		name: '',
		assigneeId: '',
		parameters: {
			include: WorklistInclude.EVERYTHING,
			payers: [],
			procedureCodes: [],
		},
	},
	payers: {
		payers: [],
		total: 0,
		totalPages: 0,
		query: '',
		isLoading: false,
		currentPage: 0,
	},
	procedureCodes: {
		procedureCodes: [],
	},
})

export default state
