import {
	fetchClient,
	fetchClientUsers,
	createClient,
	updateClient,
	updateClientCptPricing,
	updateClientPayer,
	updateInboundFax,
} from '@/service/client.http'
import {
	IClient,
	IClientDetails,
	IClientPayerMC,
	IClientTransaction,
	IInboundFax,
	IClientInformation,
	ClientTransactionTypes,
} from '@server/api/models/fe/client'
import {
	ActiveStatus,
	ClientUniqueID,
	DisplayName,
} from '@myndshft/types-mc-api'
import { createDefaultAddress } from '../../server/api/models/mappers/from-server/payer'
import { IAddressFE } from '@server/api/models/fe/shared'
import { IUserDetails } from '@server/api/models/fe/user'

const getInitialState = (): IClient => {
	return {
		client: {
			id: '' as ClientUniqueID,
			name: '' as DisplayName,
			firstName: '' as DisplayName,
			lastName: '' as DisplayName,
			npi: 0,
			address: createDefaultAddress(),
			status: 'INACTIVE' as ActiveStatus,
			willAutoActivate: 'ACTIVE' as ActiveStatus,
			serviceTypeID: '',
			crmID: '',
			crmAccountName: '',
			tenantId: '',
			federalTaxId: '',
			placeOfService: null,
			placeOfServiceCode: null,
			lastModified: null,
			lastModifiedBy: '',
		},
		users: [] as IUserDetails[],
		inboundFax: {
			username: '',
			number: '',
			secret: '',
		},
		transactions: [],
		procedures: [],
		clientPayers: {
			total: 0,
			totalPages: 0,
			payers: [],
		},
	}
}

const getters = {
	canSaveTransactions(clientState: IClient) {
		const getTransaction = (transaction: ClientTransactionTypes) => {
			return clientState.transactions.find(item => item.name === transaction)
		}
		const parTransaction = getTransaction(
			ClientTransactionTypes.PRIOR_AUTH_DETERMINATION
		)

		const pasTransaction = getTransaction(
			ClientTransactionTypes.PRIOR_AUTH_SUBMISSION
		)

		// PAS transaction must have PAR transaction enabled
		if (!!pasTransaction?.isActive && !parTransaction?.isActive) {
			return false
		}
		return true
	},
	hasUsers(clientState: IClient) {
		return !!clientState.users.length
	},
}

const mutations = {
	setClient(clientState: IClient, client: IClient) {
		Object.assign(clientState, client)
	},
	setID(clientState: IClient, id: ClientUniqueID) {
		clientState.client.id = id
	},
	setName(clientState: IClient, name: DisplayName) {
		clientState.client.name = name
	},
	setFirstName(clientState: IClient, firstName: DisplayName) {
		clientState.client.firstName = firstName
	},
	setLastName(clientState: IClient, lastName: DisplayName) {
		clientState.client.lastName = lastName
	},
	setNPI(clientState: IClient, npi: number) {
		clientState.client.npi = npi
	},
	setCrmID(clientState: IClient, accountID: string) {
		clientState.client.crmID = accountID
	},
	setTenantId(clientState: IClient, tenantId: string) {
		clientState.client.tenantId = tenantId
	},
	setAddress(clientState: IClient, address: IAddressFE) {
		clientState.client.address = address
	},
	setIsActive(clientState: IClient, status: ActiveStatus) {
		clientState.client.status = status
	},
	setWillAutoActivate(clientState: IClient, willAutoActivate: ActiveStatus) {
		clientState.client.willAutoActivate = willAutoActivate
	},
	setServiceType(clientState: IClient, serviceTypeID: string) {
		clientState.client.serviceTypeID = serviceTypeID
	},
	setTransactions(clientState: IClient, transactions: IClientTransaction[]) {
		clientState.transactions = transactions
	},
	setUsers(clientState: IClient, users: IUserDetails[]) {
		clientState.users = users
	},
	setInboundFax(clientState: IClient, inboundFax: IInboundFax) {
		clientState.inboundFax = inboundFax
	},
	setClientInformation(
		clientState: IClient,
		{ client, address, inboundFax }: IClientInformation
	) {
		clientState.client = {
			...clientState.client,
			...client,
			address: { ...clientState.client.address, ...address } as IAddressFE,
		}
		clientState.inboundFax = inboundFax
	},
}

const actions = {
	async getClient({ commit }: any, ID: string): Promise<void> {
		const client = await fetchClient(ID)
		commit('setClient', client)
	},
	async getClientUsers({ commit }: any, ID: string): Promise<void> {
		const users = await fetchClientUsers(ID)
		commit('setUsers', users)
	},
	async createClient(
		{ commit }: any,
		{ config }: { config: IClient }
	): Promise<void> {
		const response: any = await createClient(config)
		const client = await fetchClient(response.data.id)
		commit('setClient', client)
	},
	async updateClient(
		{ commit }: any,
		{ config }: { config: IClient }
	): Promise<void> {
		await updateClient(config)
		const client = await fetchClient(config.client.id)
		commit('setClient', client)
	},
	async updateClientPayer(
		{ commit }: any,
		{ id, config }: { id: ClientUniqueID; config: IClientPayerMC }
	): Promise<void> {
		await updateClientPayer(config, id)
		const client = await fetchClient(id)
		commit('setClient', client)
	},
	async updatePricing(
		{ commit }: any,
		{ id, payload }: { id: string; payload: any }
	): Promise<IClient> {
		const response = (await updateClientCptPricing(id, payload)) as any
		if (response.data && response.data.error) {
			throw response.data.error
		} else {
			const client = await fetchClient(id)
			commit('setClient', client)
			return await fetchClient(id)
		}
	},
	async updateInboundFax(
		{ commit }: any,
		{ client, fax }: { client: IClientDetails; fax: IInboundFax }
	): Promise<void> {
		await updateInboundFax(client, fax)
		const updatedClient = await fetchClient(client.id)
		commit('setClient', updatedClient)
	},
	reset({ commit }: any) {
		const resetState = getInitialState()
		commit('setClient', resetState)
	},
}

export default {
	namespaced: true,
	state: getInitialState,
	getters,
	mutations,
	actions,
}
