import axios from 'axios'
import queryString from 'query-string'

import {
	IContactPayerFax,
	IContactPayerPhone,
	IContactPayerPortal,
	IPayerConfig,
	IPayerListItem,
	IPayerListResults,
	IPriorAuthRecords,
} from '../../server/api/models/fe/payer'

import interceptor from '@/service/interceptor'
import { getApiServerV2, getBff } from './shared.http'
import { IPayer } from '../../server/api/models/fe/payer'
import { PayerUniqueID } from '@myndshft/types-mc-api'
import { IGuideline } from '../../server/api/models/fe/shared'
import {
	fromFePortalPost,
	fromFeFaxPost,
	fromFePhonePost,
	fromFeGuidelinePost,
} from '@/models/mappers/from-fe/payer'
import { fromResponseContactMethods } from '@/models/mappers/from-server/payer'
import { IResponseContactMethods } from '@/models/server-models/response-models/v2/payer'

// Assign interceptor to add request headers
axios.interceptors.request.use(interceptor as any)

export async function fetchPayerList(
	perPage: number,
	page: number,
	name: string,
	uniqueId?: string
): Promise<IPayerListResults> {
	page -= 1
	const qs = queryString.stringify({
		per_page: perPage,
		page,
		name,
		unique_id: uniqueId,
	})

	const path = `${getBff()}/payers?${qs}`
	const response = await axios.get<any>(path)
	const total = response.data.total ?? 0
	const totalPages = response.data.totalPages ?? 0
	const payers = response.data.payers ?? []
	const headers = response.data.headers
	if (headers) {
		console.warn('Headers:', headers)
	}
	return {
		payers,
		total,
		totalPages,
		headers,
	}
}

export async function fetchAllPayers(): Promise<IPayerListItem[]> {
	const path = `${getBff()}/payers/all`
	const response = await axios.get<any>(path)
	return response.data
}

export async function fetchPayer(id: PayerUniqueID): Promise<IPayer> {
	const path = `${getBff()}/payers/${id}`
	const response = await axios.get<IPayer>(path)
	return response.data
}

export async function fetchPayerIdByUniqueId(
	uniqueId: PayerUniqueID
): Promise<PayerUniqueID> {
	const perPage = 99
	const page = 1
	const name = ''

	const response = await fetchPayerList(perPage, page, name, uniqueId)
	return response.payers[0].id
}

export async function createPayer(payer: IPayer): Promise<PayerUniqueID> {
	const path = `${getBff()}/payers`
	const response = await axios.post(path, payer)
	return response.data.id
}

export async function updatePayer(payer: IPayerConfig): Promise<void> {
	const path = `${getBff()}/payers/${payer.id}`
	await axios.put(path, payer)
}

export async function createPortal(
	uuid: string,
	portal: IContactPayerPortal
): Promise<void> {
	const payload = fromFePortalPost(portal)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods`
	await axios.post(path, payload)
}

export async function updatePortal(
	uuid: string,
	portal: IContactPayerPortal
): Promise<void> {
	const payload = fromFePortalPost(portal)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${portal.id}`
	await axios.put(path, payload)
}

export async function deletePortal(
	uuid: string,
	portal: IContactPayerPortal
): Promise<void> {
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${portal.id}`
	await axios.delete(path).catch(error => {
		throw error.response.data.error.message
	})
}

export async function createFax(
	uuid: string,
	fax: IContactPayerFax
): Promise<void> {
	const payload = fromFeFaxPost(fax)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods`
	await axios.post(path, payload)
}

export async function updateFax(
	uuid: string,
	fax: IContactPayerFax
): Promise<void> {
	const payload = fromFeFaxPost(fax)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${fax.id}`
	await axios.put(path, payload)
}

export async function deleteFax(
	uuid: string,
	fax: IContactPayerFax
): Promise<void> {
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${fax.id}`
	await axios.delete(path).catch(error => {
		throw error.response.data.error.message
	})
}

export async function createPhone(
	uuid: string,
	phone: IContactPayerPhone
): Promise<void> {
	const payload = fromFePhonePost(phone)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods`
	await axios.post(path, payload)
}

export async function updatePhone(
	uuid: string,
	phone: IContactPayerPhone
): Promise<void> {
	const payload = fromFePhonePost(phone)
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${phone.id}`
	await axios.put(path, payload)
}

export async function deletePhone(
	uuid: string,
	phone: IContactPayerPhone
): Promise<void> {
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods/${phone.id}`
	await axios.delete(path).catch(error => {
		throw error.response.data.error.message
	})
}

export async function createGuideline(
	uuid: string,
	guideline: IGuideline
): Promise<void> {
	const payload = fromFeGuidelinePost(guideline)
	const path = `${getApiServerV2()}/payers/${uuid}/assets`
	await axios.post(path, payload)
}

export async function updateGuideline(
	uuid: string,
	guideline: IGuideline
): Promise<void> {
	const payload = fromFeGuidelinePost(guideline)
	const path = `${getApiServerV2()}/payers/${uuid}/assets/${guideline.id}`
	await axios.put(path, payload)
}

export async function deleteGuideline(
	uuid: string,
	guideline: IGuideline
): Promise<void> {
	const path = `${getApiServerV2()}/payers/${uuid}/assets/${guideline.id}`
	await axios.delete(path).catch(error => {
		throw error.response.data.error.message
	})
}

export async function fetchPayerContactMethods(uuid: string): Promise<any> {
	const path = `${getApiServerV2()}/payers/${uuid}/contact_methods`
	const response = await axios.get<IResponseContactMethods>(path)
	return fromResponseContactMethods(response.data)
}

export async function fetchPriorAuthRecords(
	uuid: string,
	page: number,
	perPage: number,
	code: string
): Promise<IPriorAuthRecords> {
	const path = `${getBff()}/pa_records?payer_id=${uuid}&sort=procedure_code&page=${page}&per_page=${perPage}&code=${code}`
	const response = await axios.get<IPriorAuthRecords>(path)
	return response.data
}
