import { Machine, MachineConfig } from 'xstate'
import { MachineEvent } from '@/store/utils/machine'

export interface PayerWizardSchema {
	states: {
		reset: object
		wizard: {
			states: {
				steps: {
					states: {
						payerInformation: object
						duplication: {
							states: {
								checking: object
								conflict: {
									states: {
										name: object
										uniqueId: object
									}
								}
								confirmation: object
							}
						}
						transactions: object
						confirmation: object
						creatingPayer: object
						success: object
					}
				}
				discard: {
					states: {
						idle: object
						confirmation: object
					}
				}
			}
		}
	}
}

const machineConfig: MachineConfig<any, PayerWizardSchema, MachineEvent> = {
	id: 'PayerWizard',
	strict: true,
	initial: 'reset',
	states: {
		reset: {
			id: 'reset',
			entry: ['reset'],
			on: {
				'': 'wizard',
			},
		},
		wizard: {
			type: 'parallel',
			states: {
				steps: {
					initial: 'payerInformation',
					states: {
						payerInformation: {
							id: 'payerInformation',
							on: {
								NEXT: 'duplication.checking',
							},
						},
						duplication: {
							initial: 'checking',
							states: {
								checking: {
									entry: ['checkDuplicates'],
									on: {
										FOUND_NAME_CONFLICT: 'conflict.name',
										FOUND_UNIQUE_NAME_CONFLICT: 'conflict.uniqueId',
										FOUND_POTENTIAL_DUPLICATES: 'confirmation',
										NO_DUPLICATES: '#transactions',
									},
								},
								conflict: {
									states: {
										name: {
											on: {
												PREV: '#payerInformation',
											},
										},
										uniqueId: {
											on: {
												PREV: '#payerInformation',
											},
										},
									},
								},
								confirmation: {
									on: {
										PREV: '#payerInformation',
										NEXT: '#transactions',
									},
								},
							},
						},
						transactions: {
							id: 'transactions',
							on: {
								PREV: 'payerInformation',
								NEXT: 'confirmation',
							},
						},
						confirmation: {
							on: {
								PREV: 'transactions',
								NEXT: 'creatingPayer',
							},
						},
						creatingPayer: {
							entry: ['createPayer'],
							on: {
								ERROR: 'confirmation',
								SUCCESS: '#reset',
							},
						},
						success: {},
					},
				},
				discard: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								LEAVE: 'confirmation',
							},
						},
						confirmation: {
							on: {
								CANCEL: 'idle',
								DISCARD: '#reset',
							},
						},
					},
				},
			},
		},
	},
}

export default Machine(machineConfig)
