import { IRequestWorklistItemsGET } from '@myndshft/types-mc-api'
import { IWorklistItemFilter } from '@server/api/models/fe/worklist'

export function fromFeWorklistItemFilter(
	filters: IWorklistItemFilter,
	page: number,
	sort: string,
	order: string
): IRequestWorklistItemsGET {
	if (sort) {
		sort = sort
			.replace(/([A-Z])/g, ' $1')
			.split(' ')
			.join('_')
			.toLowerCase()
		sort = sort.split('.')[0]
	}
	return {
		page,
		sort,
		order,
		payer_id: filters.payerId,
		plan_id: filters.planId,
		procedure_category: filters.procedureCategory,
		code: filters.code,
		status: filters.status,
	}
}
