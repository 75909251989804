import { extend } from 'vee-validate'
import {
	max_value,
	min_value,
	required,
	digits,
	integer,
} from 'vee-validate/dist/rules'
import { validateNPI, validateZipCode } from '@myndshft/validation'

import { IPayerPlan } from '../server/api/models/fe/payer'

export const createValidationRules = () => {
	extend('min_value', {
		...min_value,
		message: '*Value must be greater than {min}',
	})

	extend('max_value', {
		...max_value,
		message: '*Value must be less than {max}',
	})

	extend('digits', {
		...digits,
		message: '*This field must be exactly {length} digits',
	})

	extend('integer', {
		...integer,
		message: '*Must be a whole number',
	})

	extend('lt', {
		params: ['target'],
		validate(value, { target }: Record<string, any>) {
			return Number(value) < Number(target)
		},
		message: '*Value must be less than {target}',
	})

	extend('gt', {
		params: ['target'],
		validate(value, { target }: Record<string, any>) {
			return Number(value) > Number(target)
		},
		message: '*Value must be greater than {target}',
	})

	extend('mantissa', {
		validate(value: string, { numDigits }: Record<string, any>) {
			const floatRegex = /^[+-]?\d+(\.\d+)?$/
			if (!floatRegex.test(value)) {
				return false
			}
			if (value.indexOf('.') > -1) {
				return (
					value.substring(value.indexOf('.') + 1, value.length).length <=
					numDigits
				)
			}
			return true
		},
		params: ['numDigits'],
		message: '*Must be digits only and max {numDigits} decimal places',
	})

	extend('required', {
		...required,
		message: '*This field is required.',
	})

	extend('requiredMethod', {
		...required,
		message: '*At least one submission method is required.',
	})

	extend('npi', value => {
		if (validateNPI(value)) {
			return true
		}
		return '*This field must be a valid NPI.'
	})

	extend('zipcode', value => {
		if (validateZipCode(value)) {
			return true
		}
		return '*This field must be a valid zip code.'
	})

	extend('city', {
		validate(value) {
			// Adds a range of diacritics for cities that have them in their name u0080-u024F
			const regex = /^[a-zA-Z\u0080-\u024F\s/\-)(`."']+$/
			if (regex.test(value)) {
				return true
			}
			return '*A valid city name is required.'
		},
	})

	extend('flatRateRequired', {
		...required,
		message: '*A flat rate is required.',
	})

	extend('percentageRequired', {
		...required,
		message: '*A percentage is required.',
	})

	extend('percentageValue', value => {
		if (value > 0 && value <= 999) {
			return true
		}

		if (isNaN(value)) {
			return '*Percentage must be a number.'
		}

		return '*Percentage must be > 0 and <1000.'
	})

	extend('percentageInteger', value => {
		if (!value.includes('.')) {
			return true
		}

		return '*Percentage must not be a fraction.'
	})

	extend('flatRateValue', value => {
		if (value >= 0 && value <= 100000000) {
			return true
		}
		return '*Flat rate must be 0 or more but <100000000.'
	})

	extend('referenceSource', {
		...required,
		message: '*A reference source is required.',
	})

	extend('number', {
		params: ['type'],
		validate(value) {
			const regex = /^[2-9]\d{2}-\d{3}-\d{4}$/
			if (regex.test(value)) {
				return true
			}
			return '*A valid {type} number is required.'
		},
	})

	extend('url', value => {
		const regex =
			/((^ftp|^sftp|^https?):\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.,~#?&/=;]*)$/gi

		if (regex.test(value)) {
			return true
		}
		return '*A valid URL is required'
	})

	extend('email', value => {
		const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gi

		if (regex.test(value)) {
			return true
		}
		return '*A valid email is required'
	})

	extend('max', {
		validate(value, { length }) {
			return value.length <= length
		},
		params: ['length'],
		message: '*This field can only have a maximum of {length} characters',
	})

	extend('min', {
		validate(value, { length }) {
			return value.length >= length
		},
		params: ['length'],
		message: '*This field must have a minimum of {length} characters',
	})

	extend('validPlan', {
		validate(value: IPayerPlan) {
			if (value.id && value.name) {
				return true
			}
			return false
		},
		message: '*A plan is required',
	})

	extend('domain', {
		validate(value: string) {
			const domainRegex =
				/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/gm
			if (domainRegex.test(value)) {
				return true
			}
			return '*Please enter a valid domain'
		},
	})

	extend('validName', {
		validate(value: string) {
			const nameRegex = /(^[\w@#%&*\-':"+=|,. ]+$)/g
			if (nameRegex.test(value)) {
				return true
			}
			const invalidRegex = /([^\w@#%&*\-':"+=|,. ]+)/g
			const invalidChars = value.match(invalidRegex) || []
			return `*Has one or more invalid characters${
				invalidChars?.length ? ` '${invalidChars[0]}'` : ''
			}`
		},
	})

	extend('hexColor', {
		validate(value: string) {
			const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g
			if (hexRegex.test(value)) {
				return true
			}
			return '*Please enter a valid hexadecimal color starting with #'
		},
	})

	extend('dateRange', {
		params: ['target'],
		validate(value: string, { target }: any) {
			return new Date(value) > new Date(target)
		},
		message: '*End date must be after start date',
	})
}
