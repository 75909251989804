import {
	fetchTenant,
	createTenant,
	updateTenant,
	updateSetting,
	fetchTenantSettings,
	resetTenantSettings,
} from '@/service/tenant.http'
import {
	ActiveStatus,
	DisplayName,
	TenantUniqueID,
	ITenantDomain,
} from '@myndshft/types-mc-api'
import { ITenant, ITenantSetting } from '../../server/api/models/fe/tenant'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { IClientDetails } from '@server/api/models/fe/client'

export interface ITenantState {
	tenant: {
		id: TenantUniqueID
		name: DisplayName
		externalId: string
		crmId: string
		status: string
		domains: ITenantDomain[]
	}
	settings: ITenantSetting[]
	clients: IClientDetails[]
	loading: {
		settings: boolean
		clients: boolean
	}
	isLoading: boolean
	lastModified: string
	lastModifiedBy: string
}

const getInitialState = () => {
	return {
		tenant: {
			id: '',
			name: '' as DisplayName,
			externalId: '',
			crmId: '',
			status: 'INACTIVE' as ActiveStatus,
			domains: [],
		},
		settings: [],
		clients: [],
		loading: { settings: false, clients: false },
		isLoading: false,
		lastModified: '',
		lastModifiedBy: '',
	}
}

const mutations = {
	setTenant(tenantState: ITenantState, tenant: ITenant) {
		Object.assign(tenantState, tenant)
	},
	setId(tenantState: ITenantState, id: TenantUniqueID) {
		tenantState.tenant.id = id
	},
	setName(tenantState: ITenantState, name: DisplayName) {
		tenantState.tenant.name = name
	},
	setExternalId(tenantState: ITenantState, externalId: TenantUniqueID) {
		tenantState.tenant.externalId = externalId
	},
	setCrmId(tenantState: ITenantState, crmId: string) {
		tenantState.tenant.crmId = crmId
	},
	setIsLoading(tenantState: ITenantState, value: boolean) {
		tenantState.isLoading = value
	},
	setTenantSettings(tenantState: ITenantState, settings: ITenantSetting[]) {
		tenantState.settings = settings
	},
	setLoadingSettings(tenantState: ITenantState, value: boolean) {
		tenantState.loading.settings = value
	},
	setTenantClients(tenantState: ITenantState, clients: IClientDetails[]) {
		tenantState.clients = clients
	},
	setLoadingClients(tenantState: ITenantState, value: boolean) {
		tenantState.loading.clients = value
	},
	setLastModified(tenantState: ITenantState, value: string) {
		tenantState.lastModified = value
	},
	setLastModifiedBy(tenantState: ITenantState, value: string) {
		tenantState.lastModifiedBy = value
	},
	setIsActive(tenantState: ITenantState, status: ActiveStatus) {
		tenantState.tenant.status = status
	},
	setDomains(tenantState: ITenantState, domains: ITenantDomain[]) {
		tenantState.tenant.domains = domains
	},
}

const commitTenant = (commit: any, tenant: ITenant) => {
	commit('setId', tenant.tenant.id)
	commit('setExternalId', tenant.tenant.externalId)
	commit('setName', tenant.tenant.name)
	commit('setCrmId', tenant.tenant.crmId)
	commit('setIsActive', tenant.tenant.status)
	commit('setDomains', tenant.tenant.domains)
	commit('setLastModified', tenant.tenant.lastModified)
	commit('setLastModifiedBy', tenant.tenant.lastModifiedBy)
}

const actions = {
	async getTenant({ commit }: any, id: TenantUniqueID): Promise<void> {
		commit('setIsLoading', true)
		const tenant = await fetchTenant(id)
		commit('setTenant', tenant)
		commit('setTenantSettings', tenant.settings)
		commit('setTenantClients', tenant.clients)
		commit('setIsLoading', false)
	},
	async createTenant(
		{ commit }: any,
		{ config }: { config: any }
	): Promise<void> {
		const response = await createTenant(config)
		const tenant = await fetchTenant(response)
		commitTenant(commit, tenant)
	},
	async updateTenant(
		{ dispatch }: any,
		{ config }: { config: any }
	): Promise<void> {
		await updateTenant(config)
			.then(() => {
				toast({
					type: Alert.SUCCESS,
					title: 'Successfully updated Tenant',
				})
				dispatch('getTenant', config.id)
			})
			.catch(err => {
				if (err.response.status === 400 && err.response.data.message) {
					toast({
						type: Alert.ERROR,
						title: err.response.data.message,
						timer: 4000,
					})
				} else {
					toast({
						type: Alert.ERROR,
						title: 'Unknown error occurred creating Tenant',
						timer: 4000,
					})
				}
			})
	},
	async updateSetting(
		{ commit }: any,
		{ uuid, item }: { uuid: TenantUniqueID; item: ITenantSetting }
	): Promise<void> {
		await updateSetting(uuid, item)
			.then(() => {
				toast({
					type: Alert.SUCCESS,
					title:
						'Successfully updated Tenant Setting. Changes may take some time to apply.',
				})
				fetchTenant(uuid).then(tenant => commitTenant(commit, tenant))
			})
			.catch(err => {
				if (err.response.status === 400 && err.response.data.message) {
					toast({
						type: Alert.ERROR,
						title: err.response.data.message,
					})
				} else {
					toast({
						type: Alert.ERROR,
						title: 'Unknown error occurred updating Tenant Setting',
					})
				}
			})
	},
	async resetSettings({ commit, state }: any): Promise<void> {
		await resetTenantSettings(state.tenant.id)
			.then(() => {
				toast({
					type: Alert.SUCCESS,
					title:
						'Successfully reset all Tenant Settings to default. Changes may take some time to apply.',
					timer: 4000,
				})
				fetchTenantSettings(state.tenant.id).then(settings => {
					commit('setTenantSettings', settings)
				})
			})
			.catch(err => {
				if (err.response.status === 409 && err.response.data.message) {
					toast({
						type: Alert.ERROR,
						title: err.response.data.message,
					})
				} else {
					toast({
						type: Alert.ERROR,
						title:
							'Unknown error occurred while attempting to reset Tenant Settings',
					})
				}
			})
	},
	reset({ commit }: any) {
		const resetState = getInitialState()
		commit('setId', resetState.tenant.id)
		commit('setName', resetState.tenant.name)
		commit('setExternalId', resetState.tenant.externalId)
		commit('setCrmId', resetState.tenant.crmId)
		commit('setLastModified', resetState.lastModified)
		commit('setLastModifiedBy', resetState.lastModifiedBy)
		commit('setIsActive', resetState.tenant.status)
		commit('setDomains', resetState.tenant.domains)
	},
}

export default {
	namespaced: true,
	state: getInitialState,
	mutations,
	actions,
}
