
import { computed, defineComponent } from 'vue'
import { getV2Route } from '@/service/shared.http'
import { useRouter } from '@/utils/composables'

export default defineComponent({
	setup() {
		const router = useRouter()

		const payerActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/payer') ? 'is-active' : ''
		})
		const clientActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/client') ? 'is-active' : ''
		})
		const worklistActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/worklist') ? 'is-active' : ''
		})
		const tenantActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/tenant') ? 'is-active' : ''
		})
		const userManagementActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/user-management')
				? 'is-active'
				: ''
		})
		const notificationBannerActiveClass = computed(() => {
			return router.app.$route.path.startsWith('/notification')
				? 'is-active'
				: ''
		})

		return {
			payerActiveClass,
			clientActiveClass,
			worklistActiveClass,
			tenantActiveClass,
			userManagementActiveClass,
			notificationBannerActiveClass,
			getV2Route,
		}
	},
})
