import Vue from 'vue'
import Fragment from 'vue-fragment'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'

import DefaultLayout from '@/layouts/default.vue'
import TwoColumnLayout from '@/layouts/two-column.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createValidationRules } from './validations'
import vSelect from 'vue-select'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import MyndshftValidation from '@myndshft/validation'
import {
	faArrowUp,
	faArrowDown,
	faArrowRight,
	faEdit,
	faCheckSquare,
	faCheckCircle,
	faTimesCircle,
	faTrashAlt,
	faInfoCircle,
	faUser,
	faCog,
	faCheck,
	faPlus,
	faMinus,
	faHospital,
	faCircle,
	faStethoscope,
	faHeartbeat,
	faTimes,
	faSquare,
	faExchangeAlt,
	faAngleDown,
	faPlusCircle,
	faAngleUp,
	faUpload,
	faChevronUp,
	faChevronDown,
	faChevronRight,
	faChevronLeft,
	faExclamationCircle,
	faPaste,
	faClipboard,
	faLink,
	faPowerOff,
	faSearch,
	faEye,
	faEyeSlash,
	faInfo,
} from '@fortawesome/free-solid-svg-icons'
import Multiselect from 'vue-multiselect'
import CheckPermissions from './plugins/permissions/index'
import CheckFeatures from './plugins/features/index'

Vue.config.productionTip = false

Vue.use(CheckPermissions)
Vue.use(CheckFeatures)
Vue.use(Fragment.Plugin)
Vue.use(MyndshftValidation)
Vue.use(Vuetify)
Vue.use(VueSweetalert2)

library.add(faArrowUp)
library.add(faArrowDown)
library.add(faArrowRight)
library.add(faEdit)
library.add(faCheckSquare)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faTrashAlt)
library.add(faInfoCircle)
library.add(faUser)
library.add(faCog)
library.add(faCheck)
library.add(faPlus)
library.add(faMinus)
library.add(faHospital)
library.add(faStethoscope)
library.add(faHeartbeat)
library.add(faTimes)
library.add(faSquare)
library.add(faCircle)
library.add(faExchangeAlt)
library.add(faAngleDown)
library.add(faPlusCircle)
library.add(faAngleUp)
library.add(faAngleDown)
library.add(faUpload)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faExclamationCircle)
library.add(faClipboard)
library.add(faPaste)
library.add(faLink)
library.add(faPowerOff)
library.add(faSearch)
library.add(faEye)
library.add(faEyeSlash)
library.add(faInfo)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('two-column-layout', TwoColumnLayout)
Vue.component('default-layout', DefaultLayout)
Vue.component('vue-select', vSelect)
Vue.component('multi-select', Multiselect)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

createValidationRules()

const app = new Vue({
	vuetify: new Vuetify(),
	router,
	store,
	render: h => h(App),
}).$mount('#app')

if (window.Cypress) {
	// only available during E2E tests
	window.app = app
}
