import { fetchServiceTypesList } from '@/service/shared.http'
import { IServiceType } from '../../server/api/models/fe/shared'

const state: IServiceTypeState = {
	types: [],
}

const getters = {
	getTypes(serviceTypeState: IServiceTypeState) {
		return serviceTypeState.types
	},
}

const mutations = {
	setTypes(serviceTypeState: IServiceTypeState, serviceTypes: IServiceType[]) {
		serviceTypeState.types = serviceTypes
	},
}

const actions = {
	async fetchServiceTypes({ commit }: any): Promise<void> {
		const serviceTypes = await fetchServiceTypesList()
		commit('setTypes', serviceTypes)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}

interface IServiceTypeState {
	types: IServiceType[]
}
