import {
	IContactPayerPortal,
	IContactPayerFax,
	IContactPayerPhone,
	IPriorAuthRecords,
	IPayerPlan,
	IPayerTransaction,
} from '@server/api/models/fe/payer'
import {
	createPayer,
	updatePayer,
	updateFax,
	createFax,
	deleteFax,
	createGuideline,
	updateGuideline,
	deleteGuideline,
	createPhone,
	updatePhone,
	deletePhone,
	updatePortal,
	createPortal,
	deletePortal,
	fetchPayerContactMethods,
	fetchPriorAuthRecords,
	fetchPayer,
} from '@/service/payer.http'
import { createDefaultAddress } from '@server/api/models/mappers/from-server/payer'
import { fetchPlans } from '@/service/shared.http'
import {
	ActiveStatus,
	PayerUniqueID,
	NetworkAuthRequirement,
	DisplayName,
} from '@myndshft/types-mc-api'
import { IPayer, IPayerConfig } from '@server/api/models/fe/payer'
import {
	IAddressFE,
	IGuideline,
	TransactionTypes,
} from '@server/api/models/fe/shared'
import { IForm } from '@/models/worklists/outcome'

export interface IPayerState {
	payer: {
		id: string
		name: DisplayName
		uniqueId: PayerUniqueID
		status: ActiveStatus
		authRequirement: NetworkAuthRequirement
		authSubmissionOONForm: string
		address: IAddressFE | null
	}
	newPayerSelectedForm: IForm | null
	transactions: IPayerTransaction[]
	contactMethods: {
		portals: IContactPayerPortal[]
		faxes: IContactPayerFax[]
		phones: IContactPayerPhone[]
	}
	assets: IGuideline[]
	paRecords: IPriorAuthRecords
	payerPlans: IPayerPlan[]
	loading: {
		portals: boolean
		faxes: boolean
		phones: boolean
		guidelines: boolean
	}
	isLoading: boolean
	lastModified: string
	lastModifiedBy: string
}

const getInitialState = () => {
	return {
		payer: {
			id: '',
			name: '' as DisplayName,
			uniqueId: '' as PayerUniqueID,
			status: '' as ActiveStatus,
			authRequirement: NetworkAuthRequirement.NO_AUTH,
			authSubmissionOONForm: '',
			address: createDefaultAddress(),
		},
		newPayerSelectedForm: null,
		transactions: [],
		contactMethods: {
			portals: [],
			faxes: [],
			phones: [],
		},
		assets: [],
		paRecords: {
			total: 0,
			totalPages: 0,
			items: [],
		},
		payerPlans: [],
		loading: {
			portals: false,
			faxes: false,
			phones: false,
			guidelines: false,
		},
		isLoading: false,
		lastModified: '',
		lastModifiedBy: '',
	}
}

const getters = {
	getAuthSubmissionOONForm: (state: IPayerState) =>
		state.payer.authSubmissionOONForm,
	getNewPayerSelectedForm: (state: IPayerState) => state.newPayerSelectedForm,
	canSaveTransactions(state: IPayerState) {
		const getTransaction = (transaction: TransactionTypes) => {
			return state.transactions.find(item => item.name === transaction)
		}
		const parTransaction = getTransaction(TransactionTypes.PRIOR_AUTH)

		const pasTransaction = getTransaction(
			TransactionTypes.PRIOR_AUTH_SUBMISSION
		)

		// PAS transaction must have PAR transaction enabled
		if (!!pasTransaction?.isActive && !parTransaction?.isActive) {
			return false
		}
		return true
	},
}

const mutations = {
	setPayer(payerState: IPayerState, payer: IPayer) {
		Object.assign(payerState, payer)
	},
	setId(payerState: IPayerState, id: PayerUniqueID) {
		payerState.payer.id = id
	},
	setName(payerState: IPayerState, name: DisplayName) {
		payerState.payer.name = name
	},
	setUniqueId(payerState: IPayerState, uniqueId: PayerUniqueID) {
		payerState.payer.uniqueId = uniqueId
	},
	setStatus(payerState: IPayerState, status: ActiveStatus) {
		payerState.payer.status = status
	},
	setAuthRequirement(payerState: IPayerState, authReq: NetworkAuthRequirement) {
		payerState.payer.authRequirement = authReq
	},
	setAuthSubmissionOONForm(
		payerState: IPayerState,
		authSubmissionOONForm: string
	) {
		payerState.payer.authSubmissionOONForm = authSubmissionOONForm
	},
	setNewPayerSelectedForm(payerState: IPayerState, selectedForm: IForm | null) {
		payerState.newPayerSelectedForm = selectedForm
	},
	setAddress(payerState: IPayerState, address: IAddressFE) {
		payerState.payer.address = address
	},
	setTransactions(payerState: IPayerState, transactions: IPayerTransaction[]) {
		payerState.transactions = transactions
	},
	setContactPortals(payerState: IPayerState, portals: IContactPayerPortal[]) {
		payerState.contactMethods.portals = portals
	},
	setContactFaxes(payerState: IPayerState, faxes: IContactPayerFax[]) {
		payerState.contactMethods.faxes = faxes
	},
	setContactPhones(payerState: IPayerState, phones: IContactPayerPhone[]) {
		payerState.contactMethods.phones = phones
	},
	setAssetGuidelines(payerState: IPayerState, guidelines: IGuideline[]) {
		payerState.assets = guidelines
	},
	setPlans(payerState: IPayerState, plans: IPayerPlan[]) {
		payerState.payerPlans = plans
	},
	setIsLoading(payerState: IPayerState, value: boolean) {
		payerState.isLoading = value
	},
	setLoadingPortals(payerState: IPayerState, value: boolean) {
		payerState.loading.portals = value
	},
	setLoadingFaxes(payerState: IPayerState, value: boolean) {
		payerState.loading.faxes = value
	},
	setLoadingPhones(payerState: IPayerState, value: boolean) {
		payerState.loading.phones = value
	},
	setLoadingContactMethods(payerState: IPayerState, value: boolean) {
		payerState.loading.portals = value
		payerState.loading.faxes = value
		payerState.loading.phones = value
	},
	setLoadingGuidelines(payerState: IPayerState, value: boolean) {
		payerState.loading.guidelines = value
	},
	setPaRecords(payerState: IPayerState, value: IPriorAuthRecords) {
		payerState.paRecords = value
	},
	setLastModified(payerState: IPayerState, value: string) {
		payerState.lastModified = value
	},
}

const commitPayer = (commit: any, payer: IPayer) => {
	commit('setId', payer.payer.id)
	commit('setUniqueId', payer.payer.uniqueId)
	commit('setName', payer.payer.name)
	commit('setAddress', payer.payer.address)
	commit('setTransactions', payer.transactions)
	commit('setAuthRequirement', payer.payer.authRequirement)
	commit('setAuthSubmissionOONForm', payer.payer.authSubmissionOONForm)
	commit('setStatus', payer.payer.status)
}

const actions = {
	async getPayer({ commit }: any, id: PayerUniqueID): Promise<void> {
		commit('setIsLoading', true)
		const payer = await fetchPayer(id)
		commit('setPayer', payer)
		commit('setIsLoading', false)
	},
	async createPayer(
		{ commit }: any,
		{ config }: { config: any }
	): Promise<void> {
		await createPayer(config)
			.then(id => {
				return id
			})
			.then(id => {
				return fetchPayer(id)
			})
			.then(payer => {
				commitPayer(commit, payer)
			})
	},
	async updatePayer(
		{ commit }: any,
		{ id, config }: { id: PayerUniqueID; config: IPayerConfig }
	): Promise<void> {
		await updatePayer(config)
		const payer = await fetchPayer(id)
		commitPayer(commit, payer)
	},
	async getPlans({ commit }: any): Promise<void> {
		commit('setIsLoading', true)
		const plans = await fetchPlans()
		commit('setPlans', plans)
		commit('setIsLoading', false)
	},
	async getPayerContactMethods({ commit }: any, uuid: string): Promise<void> {
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPhones', methods.phones)
		commit('setContactFaxes', methods.faxes)
		commit('setContactPortals', methods.portals)
		commit('setLoadingContactMethods', false)
	},
	async createFax(
		{ commit }: any,
		{ uuid, fax }: { uuid: string; fax: IContactPayerFax }
	): Promise<void> {
		await createFax(uuid, fax)
		commit('setLoadingFaxes', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactFaxes', methods.faxes)
		commit('setLoadingFaxes', false)
	},
	async updateFax(
		{ commit }: any,
		{ uuid, fax }: { uuid: string; fax: IContactPayerFax }
	): Promise<void> {
		await updateFax(uuid, fax)
		commit('setLoadingFaxes', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactFaxes', methods.faxes)
		commit('setLoadingFaxes', false)
	},
	async deleteFax(
		{ commit }: any,
		{ uuid, fax }: { uuid: string; fax: IContactPayerFax }
	): Promise<void> {
		await deleteFax(uuid, fax)
		commit('setLoadingFaxes', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactFaxes', methods.faxes)
		commit('setLoadingFaxes', false)
	},
	async createGuideline(
		_,
		{ uuid, guideline }: { uuid: string; guideline: IGuideline }
	): Promise<void> {
		await createGuideline(uuid, guideline)
	},
	async updateGuideline(
		_,
		{ uuid, guideline }: { uuid: string; guideline: IGuideline }
	): Promise<void> {
		await updateGuideline(uuid, guideline)
	},
	async deleteGuideline(
		_,
		{ uuid, guideline }: { uuid: string; guideline: IGuideline }
	): Promise<void> {
		await deleteGuideline(uuid, guideline)
	},
	async createPhone(
		{ commit }: any,
		{ uuid, phone }: { uuid: string; phone: IContactPayerPhone }
	): Promise<void> {
		await createPhone(uuid, phone)
		commit('setLoadingPhones', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPhones', methods.phones)
		commit('setLoadingPhones', false)
	},
	async updatePhone(
		{ commit }: any,
		{ uuid, phone }: { uuid: string; phone: IContactPayerPhone }
	): Promise<void> {
		await updatePhone(uuid, phone)
		commit('setLoadingPhones', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPhones', methods.phones)
		commit('setLoadingPhones', false)
	},
	async deletePhone(
		{ commit }: any,
		{ uuid, phone }: { uuid: string; phone: IContactPayerPhone }
	): Promise<void> {
		await deletePhone(uuid, phone)
		commit('setLoadingPhones', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPhones', methods.phones)
		commit('setLoadingPhones', false)
	},
	async createPortal(
		{ commit }: any,
		{
			uuid,
			portal,
		}: {
			uuid: string
			portal: IContactPayerPortal
		}
	): Promise<void> {
		await createPortal(uuid, portal)
		commit('setLoadingPortals', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPortals', methods.portals)
		commit('setLoadingPortals', false)
	},
	async updatePortal(
		{ commit }: any,
		{ uuid, portal }: { uuid: string; portal: IContactPayerPortal }
	): Promise<void> {
		await updatePortal(uuid, portal)
		commit('setLoadingPortals', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPortals', methods.portals)
		commit('setLoadingPortals', false)
	},
	async deletePortal(
		{ commit }: any,
		{ uuid, portal }: { uuid: string; portal: IContactPayerPortal }
	): Promise<void> {
		await deletePortal(uuid, portal)
		commit('setLoadingPortals', true)
		const methods = await fetchPayerContactMethods(uuid)
		commit('setContactPortals', methods.portals)
		commit('setLoadingPortals', false)
	},
	async getPaRecords(
		{ commit }: any,
		{
			uuid,
			page,
			perPage,
			code,
		}: { uuid: string; page: number; perPage: number; code: string }
	): Promise<void> {
		const records = await fetchPriorAuthRecords(uuid, page, perPage, code)
		commit('setPaRecords', records)
	},
	reset({ commit }: any) {
		const resetState = getInitialState()
		commit('setId', resetState.payer.id)
		commit('setName', resetState.payer.name)
		commit('setUniqueId', resetState.payer.uniqueId)
		commit('setStatus', resetState.payer.status)
		commit('setAuthRequirement', resetState.payer.authRequirement)
		commit('setAuthSubmissionOONForm', resetState.payer.authSubmissionOONForm)
		commit('setAddress', resetState.payer.address)
		commit('setTransactions', resetState.transactions)
		commit('setContactPortals', resetState.contactMethods.portals)
		commit('setContactFaxes', resetState.contactMethods.faxes)
		commit('setContactPhones', resetState.contactMethods.phones)
		commit('setAssetGuidelines', resetState.assets)
		commit('setLoadingPortals', resetState.loading.portals)
		commit('setLoadingFaxes', resetState.loading.faxes)
		commit('setLoadingPhones', resetState.loading.phones)
		commit('setLoadingGuidelines', resetState.loading.guidelines)
		commit('setLastModified', resetState.lastModified)
	},
}

export default {
	namespaced: true,
	state: getInitialState,
	mutations,
	actions,
	getters,
}
