import {
	FaxPriority,
	IResponseAsset,
	IResponseListPayer,
	IResponseProcedureCategory,
	IResponseProcedureCode,
	IResponseSummary,
	IResponseWorklist,
	IResponseWorklistContactMethod,
	IResponseWorklistFaxMethod,
	IResponseWorklistItem,
	IResponseWorklistItemPayer,
	IResponseWorklistItemPlan,
	IResponseWorklistMeta,
	IResponseWorklistPayer,
	IResponseWorklistPayerPlan,
	IResponseWorklistPhoneMethod,
	IResponseWorklistPortalMethod,
	IResponseWorklists,
	IResponseWorklistUser,
} from '@myndshft/types-mc-api'
import { IResponseWorklistConflicts } from '../../bff/worklist'
import { ContactMethodType, IAsset } from '../../fe/shared'
import {
	ICategory,
	IContactFaxMethod,
	IContactMethods,
	IContactPhoneMethod,
	IContactPortalMethod,
	IProcedureCategory,
	IWorklist,
	IWorklistBasicPayer,
	IWorklistBasicPlan,
	IWorklistFilterData,
	IWorklistItem,
	IWorklistItemPayer,
	IWorklistItemPlan,
	IWorklistItemSummary,
	IWorklistPayer,
	IWorklistProcedureCode,
	IWorklists,
	IWorklistsMeta,
	IWorklistUser,
	WorklistItemStatus,
	WorklistStatus,
	IWorklistConflicts,
} from '../../fe/worklist'
import { fromResponseOutcome, fromResponsePlan } from './payer'

export function fromResponseWorklists(
	response: IResponseWorklists
): IWorklists {
	const worklists: any = response.worklists ?? []
	return {
		total: response.total ?? 0,
		totalPages: response.total_pages ?? 0,
		worklists: worklists.map((worklist: IResponseWorklist) =>
			fromResponseWorklist(worklist)
		),
	}
}

export function fromResponseWorklist(worklist: IResponseWorklist): IWorklist {
	return {
		id: worklist.id,
		name: worklist.name,
		status: worklist.status as WorklistStatus,
		count: worklist.item_summary.total_count,
		inProgressCount: worklist.item_summary.in_progress_count,
		inReviewCount: worklist.item_summary.in_review_count,
		failedPublishCount: worklist.item_summary.failed_publish_count,
		assigneeId: worklist.assignee_id || '',
		meta: fromResponseWorklistMeta(worklist._meta),
	}
}

export function fromResponseWorklistConflicts(
	conflicts: IResponseWorklistConflicts
): IWorklistConflicts {
	return {
		worklistId: conflicts.worklist_id,
		conflicts: conflicts.conflicts,
		createdAt: conflicts.created_at,
		createdBy: conflicts.created_by,
		nextPageToken: conflicts.next_page_token || null,
	}
}

export function fromResponseWorklistFilterData(
	items: IResponseWorklistItem[]
): IWorklistFilterData {
	const filterData: IWorklistFilterData = {
		payers: [],
		plans: [],
		statuses: [],
		codes: [],
	}

	const payersSet = new Set()
	const plansSet = new Set()
	const codeSet = new Set()

	for (const item of items) {
		payersSet.add(JSON.stringify(item.payer))
		plansSet.add(JSON.stringify(item.plan))
		codeSet.add(
			JSON.stringify({
				code: item.procedure_code,
				shortDescription: item.short_description || null,
			})
		)
	}

	filterData.payers = Array.from(payersSet).map(payer =>
		JSON.parse(payer as string)
	)
	filterData.plans = Array.from(plansSet).map(plan =>
		JSON.parse(plan as string)
	)
	filterData.codes = Array.from(codeSet).map(code => JSON.parse(code as string))

	return filterData
}

export function fromResponseWorklistBasicPayer(
	item: IResponseWorklistPayer
): IWorklistBasicPayer {
	return {
		id: item.id,
		name: item.name,
	}
}

export function fromResponseWorklistBasicPlan(
	plan: IResponseWorklistPayerPlan
): IWorklistBasicPlan {
	return {
		id: plan.id,
		type: plan.type,
	}
}

export function fromResponseWorklistMeta(
	meta: IResponseWorklistMeta
): IWorklistsMeta {
	const modifiedByDate: Date = new Date(meta.last_modified)
	return {
		id: meta.last_modified_by,
		name: meta.last_modified_by,
		datetime: meta.last_modified,
		displayDatetime: `${modifiedByDate.toLocaleDateString(
			'en-US'
		)} at ${modifiedByDate.toLocaleTimeString('en-US')}`,
	}
}

export function fromResponsePayer(item: IResponseListPayer): IWorklistPayer {
	return {
		id: item.id,
		name: item.name,
		plans: item.pa_summary.count
			? item.pa_summary.plans.map(fromResponsePlan)
			: [],
		paPlans: item.pa_summary.count
			? item.pa_summary.plans.map(fromResponsePlan)
			: [],
		hasPaRecords: !!item.pa_summary.count,
		uniqueId: item.unique_id,
	}
}

export function fromResponseProcedureCode(
	procedure: IResponseProcedureCode
): IWorklistProcedureCode {
	return {
		code: procedure.code,
		description: procedure.description,
		type: procedure.type,
		category: {} as ICategory,
	}
}

export function fromResponseProcedureCategory(
	category: IResponseProcedureCategory
): IProcedureCategory {
	return {
		id: category.id,
		name: category.name,
	}
}

export function fromResponseWorklistItem(
	item: IResponseWorklistItem
): IWorklistItem {
	const modifiedByDate: Date = new Date(item._meta.last_modified)
	return {
		active: item.active,
		id: item.id,
		payer: fromResponseWorklistItemPayer(item.payer),
		plan: fromResponseWorklistItemPlan(item.plan),
		procedureCode: item.procedure_code,
		shortDescription: item.short_description || '',
		procedureCategory: item.procedure_category,
		outcome: fromResponseOutcome(item.outcome),
		status: item.status as WorklistItemStatus,
		requirementSet: item.requirement_set,
		summary: fromResponseWorklistItemSummary(item.summary),
		meta: {
			lastModified: `${modifiedByDate.toLocaleDateString(
				'en-US'
			)} at ${modifiedByDate.toLocaleTimeString('en-US')}`,
			lastModifiedBy: item._meta.last_modified_by,
		},
	}
}

export function fromResponseWorklistItemSummary(
	summary: IResponseSummary
): IWorklistItemSummary {
	return {
		meetsRequirements: summary.meets_requirements,
	}
}

export function fromResponseAsset(asset: IResponseAsset): IAsset {
	return {
		id: asset.id,
		type: asset.type,
		name: asset.name,
		url: asset.url,
		_meta: asset._meta
			? {
					lastModified: asset._meta.last_modified,
					lastModifiedBy: asset._meta.last_modified_by,
			  }
			: null,
	}
}

export function fromResponseItemContactMethod(
	methods: IResponseWorklistContactMethod[]
): IContactMethods {
	const contactMethods: IContactMethods = {
		phone: null,
		faxes: [],
		portal: null,
	}

	if (methods) {
		methods.forEach((method: any) => {
			switch (method.type) {
				case ContactMethodType.PHONE:
					contactMethods.phone = method
					break
				case ContactMethodType.FAX:
					contactMethods.faxes.push(method)
					break
				case ContactMethodType.PORTAL:
					contactMethods.portal = method
			}
		})
	}
	return contactMethods
}

export function fromResponseWorklistItemPayer(
	item: IResponseWorklistItemPayer
): IWorklistItemPayer {
	return {
		id: item.id,
		name: item.name,
	}
}

export function fromResponseWorklistItemPlan(
	plan: IResponseWorklistItemPlan
): IWorklistItemPlan {
	return {
		id: plan.id,
		type: plan.type,
	}
}

export function fromResponseWorklistUser(
	user: IResponseWorklistUser
): IWorklistUser {
	return {
		assigneeId: user.name,
		permissions: user.roles,
	}
}

export function fromResponseWorklistItemPhones(
	phone: IResponseWorklistPhoneMethod
): IContactPhoneMethod {
	return {
		id: phone.id,
		name: phone.name,
		number: phone.number,
		planId: phone.plan.id,
		type: phone.type as ContactMethodType.PHONE,
	}
}

export function fromResponseWorklistItemFaxes(
	fax: IResponseWorklistFaxMethod
): IContactFaxMethod {
	return {
		id: fax.id,
		name: fax.name,
		number: fax.number,
		planId: fax.plan.id,
		priority: fax.priority as FaxPriority,
		type: fax.type as ContactMethodType.FAX,
	}
}

export function fromResponseWorklistItemPortals(
	portal: IResponseWorklistPortalMethod
): IContactPortalMethod {
	return {
		id: portal.id,
		name: portal.name,
		planId: portal.plan.id,
		url: portal.url,
		type: portal.type as ContactMethodType.PORTAL,
	}
}
