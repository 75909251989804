import { Machine, MachineConfig } from 'xstate'
import { MachineEvent } from '@/store/utils/machine'

export interface TenantWizardSchema {
	states: {
		reset: object
		wizard: {
			states: {
				steps: {
					states: {
						tenantInformation: object
						confirmation: object
						creatingTenant: object
						success: object
					}
				}
				discard: {
					states: {
						idle: object
						confirmation: object
					}
				}
			}
		}
	}
}

const machineConfig: MachineConfig<any, TenantWizardSchema, MachineEvent> = {
	id: 'TenantWizard',
	strict: true,
	initial: 'reset',
	states: {
		reset: {
			id: 'reset',
			entry: ['reset'],
			on: {
				'': 'wizard',
			},
		},
		wizard: {
			type: 'parallel',
			states: {
				steps: {
					initial: 'tenantInformation',
					states: {
						tenantInformation: {
							id: 'tenantInformation',
							on: {
								NEXT: 'confirmation',
							},
						},
						confirmation: {
							on: {
								PREV: 'tenantInformation',
								NEXT: 'creatingTenant',
							},
						},
						creatingTenant: {
							entry: ['createTenant'],
							on: {
								ERROR: 'confirmation',
								SUCCESS: '#reset',
							},
						},
						success: {},
					},
				},
				discard: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								LEAVE: 'confirmation',
							},
						},
						confirmation: {
							on: {
								CANCEL: 'idle',
								DISCARD: '#reset',
							},
						},
					},
				},
			},
		},
	},
}

export default Machine(machineConfig)
