import { watchEffect, ref } from 'vue'
import { useStore, useRouter } from '@/utils/composables'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Alert, closeSwal, getTitle, toast } from '@/dependencies/sweet-alert'

dayjs.extend(relativeTime)

export function useAuth() {
	const store = useStore()
	const router = useRouter()
	const isLoggedIn = ref(store.state.appUser.user !== null)

	watchEffect(() => {
		isLoggedIn.value = store.state.appUser.user !== null
	})

	let expireInterval: any = null

	function _runCheck() {
		if (store.state.appUser.user && !store.state.appUser.isPendingLogout) {
			const expiresAt = dayjs(store.state.appUser.user.expiresAt)

			if (Math.floor(expiresAt.diff(dayjs(), 'minute', true)) <= 5) {
				// Show session logout message when token expiration <= 5 minutes
				const expireMessage = () =>
					`ATTENTION: Your session will expire ${expiresAt.fromNow()}. Click Refresh to initiate Google Sign-in.`

				if (expiresAt.diff(dayjs(), 'second', true) <= 30) {
					// expiration has expired, close swal, clear interval, logout and navigate to login page
					closeSwal()
					clearInterval(expireInterval)

					store.dispatch('appUser/logout').then(() => {
						router.push({ path: '/' })
					})
				} else if (!getTitle()) {
					// Session logout message exits; update the expire message
					toast({
						type: Alert.INFO,
						title: expireMessage(),
						timer: expiresAt.diff(dayjs(), 'second') * 1000,
						showConfirmButton: true,
						confirmButtonText: 'Refresh',
					}).then(result => {
						// User has selected to refresh their token
						if (result.value) {
							store.dispatch('appUser/refresh', null).catch(() => {
								toast({
									type: Alert.ERROR,
									title:
										'Failed to refresh token. You have been signed out for security purposes.',
									timer: 10000,
								})

								router.push({ path: '/' })
							})
						}
					})
				} else {
					// Update notification expiration message
					const sweetAlertTitle = getTitle()
					if (sweetAlertTitle?.textContent) {
						sweetAlertTitle.textContent = expireMessage()
					}
				}
			}
		}
	}

	function checkExpiration() {
		// fire once immediately, then every 30 seconds
		_runCheck()
		expireInterval = setInterval(() => {
			_runCheck()
		}, 30 * 1000)
	}

	return { isLoggedIn, checkExpiration }
}
