import axios, { AxiosRequestConfig } from 'axios'
import store from '@/store'
import dayjs from 'dayjs'
import { getApiServerV2 } from '@/service/shared.http'

const interceptor = axios.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const state = store.state as any

		if (state && state.appUser) {
			const user = state.appUser.user

			if (user) {
				const expired = dayjs(user.expiresAt).diff(dayjs(), 'second', true) <= 0
				if (expired) {
					return {
						...config,
						cancelToken: new axios.CancelToken(cancel =>
							cancel('Auth is expired')
						),
					}
				}
				config.headers.common['x-myndshft-rbac-idtoken'] = user.tokenId
				config.headers.common['x-myndshft-rbac-user'] = user.email

				if (getApiServerV2().includes('localhost')) {
					config.headers.common['x-myndshft-user'] = user.email
				}
			}
		}

		return config
	}
)

export default interceptor
