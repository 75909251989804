import LogRocket from 'logrocket'

import { IGoogleuserResponseJWT } from '../../server/api/models/fe/appUser'

import {
	IWorklistCreate,
	IWorklistItem,
	IWorklistPayer,
} from '@server/api/models/fe/worklist'
import { WorklistItemStatus } from '@myndshft/types-mc-api/src/shared/enums/worklist'

const requestSanitizer = request => {
	if (!request.body) {
		return request
	}

	if (request.url.includes('inboundFax')) {
		// Sanitize inboundFax requests; response does not need to
		// be sanitized since API does not return the secret
		const body = JSON.parse(request.body)
		body.fax.secret = 'redacted'

		request.body = JSON.stringify(body)
	}

	if (request.url.includes('/client/')) {
		const body = JSON.parse(request.body)

		// Sanitize federalTaxId if it exists
		if (body.client.federalTaxId) {
			body.client.federalTaxId = 'redacted'

			request.body = JSON.stringify(body)
		}
	}

	return request
}

const responseSanitizer = response => {
	if (!response.body) {
		return response
	}

	if (response.url.includes('/client/')) {
		const body = JSON.parse(response.body)

		// Sanitize federalTaxId if it exists
		if (body.client.federalTaxId) {
			body.client.federalTaxId = 'redacted'

			response.body = JSON.stringify(body)
		}
	}

	return response
}

export function initLogRocket(env: string) {
	if (env === 'sandbox' || env === 'production') {
		LogRocket.init('ta8rj2/myndcontrol', {
			network: {
				requestSanitizer,
				responseSanitizer,
			},
			dom: {
				inputSanitizer: true,
			},
		})
	}
}

export function logRocketIdentifyUser(userProfile: IGoogleuserResponseJWT) {
	LogRocket.identify(userProfile.email, {
		name: `${userProfile.given_name} ${userProfile.family_name}`,
		email: userProfile.email,
	})
}

export function logRocketSetEvent(eventName: string, eventDetails: any = {}) {
	const event = { ...eventDetails }

	LogRocket.track(eventName, event)
}

// Worklist creation happens in two stores (wizard and upload), so adding a helper function here
export function createWorklistLogRocketEvent(worklist: IWorklistCreate) {
	// Gather tracking data
	const payers = worklist.parameters.payers
	const procedureCodesCount = worklist.parameters.procedureCodes.length
	const plansCount = payers.reduce(
		(accumulator: number, payer: IWorklistPayer) => {
			return accumulator + payer.plans.length
		},
		0
	)

	// Track created worklist
	logRocketSetEvent('WorklistCreatedEvent', {
		worklistName: worklist.name,
		items: payers.length * plansCount * procedureCodesCount,
	})
}

export function logRocketBulkStatusEvent(
	items: IWorklistItem[],
	status: WorklistItemStatus
) {
	const itemsCount = items.length

	// Track updated worklist item status
	logRocketSetEvent('AddBulkStatusEvent', {
		itemsCount,
		status,
	})
}
