export type TableColumns<T extends object> = Array<TableColumn<T>>
export type TableData<T extends object> = Array<T>

export interface TableProps<T extends object> {
	columns: TableColumns<T>
	data: TableData<T>

	// Filtering
	filterBy?: (e: T) => boolean

	// Options
	hoverable?: boolean
	sortable?: boolean
}

export interface TableColumnSelect {
	label?: string
	onSelect: (value: boolean) => any
	isSelected: boolean
}

export interface TableColumn<T extends object> {
	field: keyof T | null
	label: string
	width?: string
	sortBy?: (e1: any, e2: any) => number
	select?: TableColumnSelect
}

export enum TableSortDirection {
	ASC = 'ASC',
	DESC = 'DESC',
}
