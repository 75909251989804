import { Permissions } from '../../../server/api/models/fe/appUser'

export default {
	install(Vue: any) {
		const canUse = (permission: Permissions, permissions: any): boolean => {
			return !!(permissions || []).some((p: any) => p.name === permission)
		}

		Vue.prototype.$canDeleteClientDetail = function () {
			return canUse(
				Permissions.CLIENT_DETAIL_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeleteClient = function () {
			return canUse(
				Permissions.CLIENT_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditClientDetail = function () {
			return canUse(
				Permissions.CLIENT_DETAIL_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeletePayerDetail = function () {
			return canUse(
				Permissions.PAYER_DETAIL_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditPayerDetail = function () {
			return canUse(
				Permissions.PAYER_DETAIL_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditPayer = function () {
			return canUse(
				Permissions.PAYER_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeleteReferenceRate = function () {
			return canUse(
				Permissions.REFERENCE_RATE_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditReferenceRate = function () {
			return canUse(
				Permissions.REFERENCE_RATE_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditWorklist = function () {
			return canUse(
				Permissions.WORKLIST_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeleteWorklist = function () {
			return canUse(
				Permissions.WORKLIST_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeleteWorklistDetail = function () {
			return canUse(
				Permissions.WORKLIST_DETAIL_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditWorklistDetail = function () {
			return canUse(
				Permissions.WORKLIST_DETAIL_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canPublishWorklist = function () {
			return canUse(
				Permissions.WORKLIST_PUBLISH,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditTenantDetail = function () {
			return canUse(
				Permissions.TENANT_DETAIL_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$isEveryone = function () {
			return canUse(
				Permissions.EVERYONE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditIDPUserDetail = function () {
			return canUse(
				Permissions.IDP_USER_DETAIL_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canResetTenantSettings = function () {
			return canUse(
				Permissions.TENANT_DETAIL_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canEditBanner = function () {
			return canUse(
				Permissions.BANNER_EDIT,
				this.$store.state.appUser.user?.permissions
			)
		}
		Vue.prototype.$canDeleteBanner = function () {
			return canUse(
				Permissions.BANNER_DELETE,
				this.$store.state.appUser.user?.permissions
			)
		}
	},
}
