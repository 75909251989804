import { State } from 'xstate'
import { MachineEvent } from '@/store/utils/machine'
import { TenantWizardSchema } from '@/store/tenant-wizard/machine'

function setState(
	state: any,
	newState: State<any, MachineEvent, TenantWizardSchema>
) {
	state.state = newState.value
}

export default { setState }
