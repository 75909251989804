
import { defineComponent, computed } from 'vue'
import { useStore, useRouter } from '@/utils/composables'

interface IEnvironment {
	isProduction: boolean
	name: string
}

export default defineComponent({
	setup() {
		const store = useStore()
		const router = useRouter()

		const resetState = () => {
			store.dispatch('payer/reset')
		}

		const onLogout = () => {
			store.dispatch('appUser/logout').then(() => {
				resetState()
				router.push({ path: '/' })
			})
		}

		const environmentName: string = store.state.environment.environment
		const environment: IEnvironment = {
			isProduction: environmentName === 'production',
			name: environmentName,
		}

		return {
			user: computed(() => store.state.appUser.user),
			isPendingLogout: computed(() => store.state.appUser.isPendingLogout),
			isLoggedIn: computed(() => store.getters['appUser/isLoggedIn']),
			environment,
			onLogout,
		}
	},
})
