import Vue from 'vue'
import jwt_decode from 'jwt-decode'
import LogRocket from 'logrocket'
import { logRocketIdentifyUser } from '@/utils/logrocket'
import {
	IAppUser,
	IAppUserState,
	IGoogleUserResponse,
	IGoogleuserResponseJWT,
} from '../../server/api/models/fe/appUser'
import { IPermission } from '@myndshft/types-mc-api'
import { fetchPermissions } from '@/service/appUser.http'

const state: IAppUserState = {
	user: null,
	isPendingLogout: false,
	clientId: null,
}

const getters = {
	isLoggedIn(userState: IAppUserState): boolean {
		return (
			!!userState.user &&
			!userState.isPendingLogout &&
			!getters.hasExpiredToken(userState)
		)
	},
	isPendingLogout(userState: IAppUserState): boolean {
		return userState.isPendingLogout
	},
	hasExpiredToken(userState: IAppUserState) {
		if (userState.user) {
			return Date.now() >= userState.user.expiresAt
		}
		return true
	},
	hasPermissions(userState: IAppUserState): boolean {
		if (userState.user) {
			return !!userState.user.permissions.length
		}
		return false
	},
	user(userState: IAppUserState): IAppUser | null {
		return userState.user
	},
}

const mutations = {
	login(userState: IAppUserState, googleUser: IGoogleUserResponse): void {
		const userProfile: IGoogleuserResponseJWT = jwt_decode(
			googleUser.credential
		)

		userState.clientId = googleUser.clientId

		Vue.set(userState, 'user', {
			email: userProfile.email,
			expiresAt: userProfile.exp * 1000,
			id: userProfile.email,
			imageUrl: userProfile.picture,
			name: userProfile.name,
			firstName: userProfile.given_name,
			lastName: userProfile.family_name,
			tokenId: googleUser.credential,
			permissions: [],
		})

		logRocketIdentifyUser(userProfile)
	},
	refreshUserToken(
		userState: IAppUserState,
		googleUser: IGoogleUserResponse
	): void {
		if (userState.user && googleUser) {
			const userProfile: IGoogleuserResponseJWT = jwt_decode(
				googleUser.credential
			)

			userState.user.tokenId = googleUser.credential
			userState.user.expiresAt = userProfile.exp * 1000
		}
	},
	logout(userState: IAppUserState) {
		userState.user = null
		userState.clientId = null
	},
	isPendingLogout(userState: IAppUserState, pendingLogout: boolean) {
		userState.isPendingLogout = pendingLogout
	},
	setPermissions(userState: IAppUserState, permissions: IPermission[]): void {
		if (userState.user) {
			userState.user.permissions = permissions
		}
	},
}

const actions = {
	login({ commit, dispatch }: any, user: IGoogleUserResponse) {
		commit('isPendingLogout', false)
		commit('login', user)
		dispatch('getPermissions')
	},
	logout({ commit }: any) {
		commit('isPendingLogout', true)
		if (window.google !== undefined) {
			commit('logout')
			commit('isPendingLogout', false)
		}
	},
	refresh({ state: userState, commit }: any) {
		return new Promise((resolve, reject) => {
			if (window.google !== undefined) {
				window.google.accounts.id.initialize({
					client_id: userState.clientId,
					auto_select: true,
					use_fedcm_for_prompt: true,
					callback: (user: any) => {
						commit('refreshUserToken', user)
					},
				})
				window.google.accounts.id.prompt((notification: any) => {
					// only way to catch a refresh failure
					if (notification?.isSkippedMoment()) {
						LogRocket.captureMessage('Failed to refresh token', {
							tags: {
								token: 'exception',
							},
						})
						reject('Refresh Failed')
					} else {
						resolve('Success')
					}
				})
			} else {
				reject('GSI not loaded')
			}
		})
	},
	async getPermissions({ commit }: any): Promise<void> {
		await fetchPermissions().then(res => {
			commit('setPermissions', (res as any).roles)
		})
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
