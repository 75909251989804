
import { defineComponent, onMounted } from 'vue'
import { useStore } from '@/utils/composables'
import { useAuth } from '@/features/auth'

import Sidebar from '@/components/sidebar.vue'

export default defineComponent({
	components: { Sidebar },
	setup() {
		const store = useStore()
		const { isLoggedIn } = useAuth()

		onMounted(() => {
			if (isLoggedIn) {
				store.dispatch('cpts/fetchCPTs')
				store.dispatch('roles/fetchRoles')
			}
		})

		return {
			isLoggedIn,
		}
	},
})
