import wizardMachine from './machine'
import { ActionContext } from 'vuex'

import router from '@/router/index'
import { transition } from '@/store/utils/machine'
import { toast, Alert } from '@/dependencies/sweet-alert'

async function reset({ commit, dispatch }: ActionContext<any, any>) {
	dispatch('tenant/reset')
	commit('setState', wizardMachine.initial)
}

async function createTenant({ dispatch, state }: ActionContext<any, any>) {
	const creatingTimeout = setTimeout(() => {
		toast({
			type: Alert.INFO,
			title: 'Still attempting to create tenant. Please wait.',
		})
	}, 5000)
	try {
		await dispatch('tenant/createTenant', { config: state.tenant })
		if (state.tenant.tenant.id) {
			toast({
				type: Alert.SUCCESS,
				title: 'Successfully created Tenant',
			})
			router.push(`/tenant/${state.tenant.tenant.id || 'list'}`)
			dispatch('transition', { type: 'SUCCESS' })
		}
	} catch (error: any) {
		if (error.response.status === 409 && error.response.data.message) {
			toast({
				type: Alert.ERROR,
				title: error.response.data.message,
			})
		} else {
			toast({
				type: Alert.ERROR,
				title: 'Unknown error occurred creating Tenant',
			})
		}
		dispatch('transition', { type: 'ERROR' })
	} finally {
		clearTimeout(creatingTimeout)
	}
}

export default {
	transition: transition.bind(null, wizardMachine),
	reset,
	createTenant,
}
