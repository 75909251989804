import wizardMachine from './machine'
import { ActionContext } from 'vuex'
import { fetchPayerIdByUniqueId, fetchPayerList } from '@/service/payer.http'

import router from '@/router/index'
import stringSimilarity from 'string-similarity'
import { sortByProjection } from '@/components/table/features/sortable'
import { transition } from '@/store/utils/machine'
import { toast, Alert } from '@/dependencies/sweet-alert'

async function reset({ commit, dispatch }: ActionContext<any, any>) {
	dispatch('payer/reset')
	commit('setState', wizardMachine.initial)
	commit('setPayerDuplicates', [])
}

async function checkDuplicates({
	commit,
	dispatch,
	state,
}: ActionContext<any, any>) {
	try {
		const perPage = 99
		const page = 1
		const name = ''

		const { payers } = await fetchPayerList(perPage, page, name)

		// Find matching or near-matching payer names
		const similarPayerIds = payers
			.map(payer => {
				return {
					...payer,
					coefficient: stringSimilarity.compareTwoStrings(
						state.payer.payer.name.toLowerCase(),
						payer.name.toLowerCase()
					),
				}
			})
			.filter(payer => payer.coefficient > 0.5)
			.sort(sortByProjection('coefficient'))
			.reverse()

		// Find matching payer unique name
		const similarPayerUniqueIds = payers
			.map(payer => {
				return {
					...payer,
					coefficient: stringSimilarity.compareTwoStrings(
						state.payer.payer.uniqueId.toLowerCase(),
						payer.uniqueId.toLowerCase()
					),
				}
			})
			.filter(payer => payer.coefficient === 1.0)

		if (similarPayerIds.length > 0 || similarPayerUniqueIds.length === 1) {
			const exactIdMatch = similarPayerIds.find(
				payer => payer.coefficient === 1.0
			)

			if (exactIdMatch !== undefined) {
				commit('setConflictPayer', exactIdMatch)
				dispatch('transition', { type: 'FOUND_NAME_CONFLICT' })
			} else if (similarPayerUniqueIds.length) {
				commit('setConflictPayer', similarPayerUniqueIds[0])
				dispatch('transition', { type: 'FOUND_UNIQUE_NAME_CONFLICT' })
			} else {
				commit('setPayerDuplicates', similarPayerIds)
				dispatch('transition', { type: 'FOUND_POTENTIAL_DUPLICATES' })
			}
		} else {
			dispatch('transition', { type: 'NO_DUPLICATES' })
		}
	} catch (error) {
		console.warn(error)
	}
}

async function createPayer({ dispatch, state }: ActionContext<any, any>) {
	try {
		await dispatch('payer/createPayer', { config: state.payer })
		toast({ type: Alert.SUCCESS, title: 'Successfully created the payer' })

		fetchPayerIdByUniqueId(state.payer.payer.uniqueId).then(id => {
			router.push(`/payer/${id || 'list'}`)
		})

		dispatch('transition', { type: 'SUCCESS' })
	} catch (error) {
		console.warn(error)
		toast({ type: Alert.ERROR, title: error, error })
		dispatch('transition', { type: 'ERROR' })
	}
}

export default {
	transition: transition.bind(null, wizardMachine),
	reset,
	checkDuplicates,
	createPayer,
}
