import axios from 'axios'
import store from '@/store/index'

import {
	IPlaceOfService,
	IServiceType,
} from '../../server/api/models/fe/shared'
import { IPayerPlan } from '../../server/api/models/fe/payer'
import { IResponseProcedureCodesGET } from '@myndshft/types-mc-api'

export function getApiServerV2(): string {
	return (store as any)?.state?.environment?.myndcontrol ?? 'Not Available'
}

export function getV2Route(): string {
	return (store as any)?.state?.environment?.v2Route ?? 'Not Available'
}

export function getBff(): string {
	return process.env.NODE_ENV === 'development'
		? 'http://localhost:3000/api'
		: '/api'
}

export async function fetchCPTList(code?: string) {
	const search = code ? `procedures?code=${code}` : 'procedures'
	const path = `${getBff()}/${search}`
	const response = await axios.get<IResponseProcedureCodesGET>(path)
	return response.data
}

export async function fetchServiceTypesList() {
	const path = `${getBff()}/service_type_categories`
	const response = await axios.get<IServiceType[]>(path)
	return response.data
}

export async function fetchPlans(): Promise<IPayerPlan[]> {
	const path = `${getBff()}/plans`
	const response = await axios.get<IPayerPlan[]>(path)
	return response.data
}

export async function fetchPlacesOfService() {
	const path = `${getBff()}/places_of_service`
	const response = await axios.get<IPlaceOfService[]>(path)
	return response.data
}
