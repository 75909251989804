import { MachineConfig, Machine } from 'xstate'
import { MachineEvent } from '../utils/machine'

export enum WorklistWizardStep {
	STEP_ONE = 'stepOne',
	STEP_TWO = 'stepTwo',
	STEP_THREE = 'stepThree',
	CONFIRMATION = 'confirmation',
	CREATING_WORKLIST = 'creatingWorklist',
	SUCCESS = 'success',
}

export interface WorklistWizardSchema {
	states: {
		reset: object
		wizard: {
			states: {
				steps: {
					states: {
						stepOne: object
						stepTwo: object
						stepThree: object
						confirmation: object
						creatingWorklist: object
						success: object
					}
				}
				discard: {
					states: {
						idle: object
						confirmation: object
					}
				}
			}
		}
	}
}

const machineConfig: MachineConfig<any, WorklistWizardSchema, MachineEvent> = {
	id: 'WorklistCreateWizard',
	strict: true,
	initial: 'reset',
	states: {
		reset: {
			id: 'reset',
			entry: ['reset'],
			on: {
				'': 'wizard',
			},
		},
		wizard: {
			type: 'parallel',
			states: {
				steps: {
					initial: WorklistWizardStep.STEP_ONE,
					states: {
						stepOne: {
							id: WorklistWizardStep.STEP_ONE,
							entry: ['getPayers'],
							on: {
								NEXT: WorklistWizardStep.STEP_TWO,
							},
						},
						stepTwo: {
							id: WorklistWizardStep.STEP_TWO,
							on: {
								PREV: WorklistWizardStep.STEP_ONE,
								NEXT: WorklistWizardStep.STEP_THREE,
							},
						},
						stepThree: {
							id: WorklistWizardStep.STEP_THREE,
							entry: ['getProcedures'],
							on: {
								PREV: WorklistWizardStep.STEP_TWO,
								NEXT: WorklistWizardStep.CONFIRMATION,
							},
						},
						confirmation: {
							id: WorklistWizardStep.CONFIRMATION,
							on: {
								PREV: WorklistWizardStep.STEP_THREE,
								NEXT: WorklistWizardStep.CREATING_WORKLIST,
							},
						},
						creatingWorklist: {
							entry: ['createWorklist'],
							on: {
								ERROR: WorklistWizardStep.CONFIRMATION,
								SUCCESS: '#reset',
							},
						},
						success: {},
					},
				},
				discard: {
					initial: 'idle',
					states: {
						idle: {
							on: {
								LEAVE: WorklistWizardStep.CONFIRMATION,
							},
						},
						confirmation: {
							on: {
								CANCEL: 'idle',
								DISCARD: '#reset',
							},
						},
					},
				},
			},
		},
	},
}

export default Machine(machineConfig)
