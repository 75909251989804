import {
	IRequestPortal,
	ContactMethodType,
	IRequestFax,
	IRequestPhone,
	IRequestGuideline,
} from '@myndshft/types-mc-api'
import { IGuideline } from '@server/api/models/fe/shared'
import {
	IContactPayerFax,
	IContactPayerPhone,
	IContactPayerPortal,
} from '@server/api/models/fe/payer'

export function fromFePortalPost(portal: IContactPayerPortal): IRequestPortal {
	return {
		name: portal.name,
		type: portal.type as ContactMethodType,
		plan: {
			id: portal.plan.id,
		},
		url: portal.url,
	}
}

export function fromFeFaxPost(fax: IContactPayerFax): IRequestFax {
	return {
		number: fax.number,
		priority: fax.priority,
		name: fax.name,
		plan: {
			id: fax.plan.id,
		},
		type: fax.type as ContactMethodType,
	}
}

export function fromFePhonePost(phone: IContactPayerPhone): IRequestPhone {
	return {
		name: phone.name,
		type: phone.type as ContactMethodType,
		plan: {
			id: phone.plan.id,
		},
		number: phone.number,
	}
}

export function fromFeGuidelinePost(guideline: IGuideline): IRequestGuideline {
	return {
		name: guideline.name,
		type: guideline.type,
		url: guideline.url,
	}
}
