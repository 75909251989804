import { fetchUserRoles } from '@/service/user.http'
import { IUserRole } from 'server/api/models/fe/role'

interface IRoleState {
	roles: IUserRole[]
}
const state: IRoleState = {
	roles: [],
}

const getters = {}

const mutations = {
	setRoles(roleState: IRoleState, roles: IUserRole[]) {
		roleState.roles = roles
	},
}

const actions = {
	async fetchRoles({ commit }: any): Promise<void> {
		const roles = await fetchUserRoles()
		commit('setRoles', roles)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
