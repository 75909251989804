import { State } from 'xstate'
import { MachineEvent } from '@/store/utils/machine'
import { PayerWizardSchema } from '@/store/payer-wizard/machine'
import { IPayer, IPayerListResults } from '@server/api/models/fe/payer'

function setState(
	state: any,
	newState: State<any, MachineEvent, PayerWizardSchema>
) {
	state.state = newState.value
}

function setPayerDuplicates(
	state: any,
	payerDuplicates: IPayerListResults['payers']
) {
	state.duplicates = payerDuplicates
}

function setConflictPayer(state: any, conflictPayer: IPayer) {
	state.conflictPayer = conflictPayer
}

export default {
	setState,
	setPayerDuplicates,
	setConflictPayer,
}
