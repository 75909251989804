import wizardMachine from './machine'
import { ActionContext } from 'vuex'

import { toast, Alert } from '@/dependencies/sweet-alert'
import { createWorklistLogRocketEvent } from '@/utils/logrocket'

import router from '@/router/index'
import { transition } from '@/store/utils/machine'
import {
	createWorklist as newWorklist,
	fetchPayers,
} from '@/service/worklist.http'
import store from '@/store/index'
import { IWorklistState } from './state'
import { IPayerPlan } from '../../../server/api/models/fe/payer'
import {
	IWorklistCreate,
	IWorklistProcedureCode,
	IWorklistPayer,
	WorklistInclude,
} from '../../../server/api/models/fe/worklist'

async function reset({ commit }: ActionContext<IWorklistState, any>) {
	commit('setState', wizardMachine.initial)
	commit('setWorklist', {
		name: '',
		assigneeId: '',
		parameters: {
			include: WorklistInclude.EVERYTHING,
			payers: [],
			procedureCodes: [],
		},
	} as IWorklistCreate)
}

async function createWorklist({
	dispatch,
}: ActionContext<IWorklistState, any>) {
	try {
		await newWorklist(store.state.worklistUploadWizard.worklist)
		toast({ type: Alert.SUCCESS, title: 'Successfully created the worklist' })

		// Track created worklist
		createWorklistLogRocketEvent(store.state.worklistUploadWizard.worklist)

		router.push(`/worklist/list`)
		dispatch('transition', { type: 'SUCCESS' })
	} catch (error: any) {
		let message = error

		if (error.response && error.response.data) {
			message = error.response.data.error.message
		}

		toast({ type: Alert.ERROR, title: message, error })
		dispatch('transition', { type: 'ERROR' })
	}
}

async function getAllPayers({
	state,
	commit,
}: ActionContext<IWorklistState, any>) {
	if (state.payers.payers.length === 0) {
		commit('setPayersLoading', true)

		const payerData = await fetchPayers(state.payers.currentPage)
		commit('setPayers', payerData)
		commit('incrementPayerPage')

		while (state.payers.currentPage <= state.payers.totalPages) {
			const data = await fetchPayers(state.payers.currentPage)
			commit('setPayers', data)
			commit('incrementPayerPage')
		}
		commit('setPayersLoading', false)
	}
}

function addParameterProcedures(
	{ commit }: ActionContext<IWorklistState, any>,
	codes: IWorklistProcedureCode[]
) {
	commit('addParameterProcedures', codes)
}

function addParameterPayers(
	{ commit }: ActionContext<IWorklistState, any>,
	payers: IWorklistPayer[]
) {
	commit('addParameterPayers', payers)
}

function setInclude(
	{ commit }: ActionContext<IWorklistState, any>,
	include: WorklistInclude
) {
	commit('setInclude', include)
}

function removeParameterPayer(
	{ commit }: ActionContext<IWorklistState, any>,
	index: number
) {
	commit('removeParameterPayer', index)
}

function removeParameterPlan(
	{ commit }: ActionContext<IWorklistState, any>,
	{ payerId, plan }: { payerId: string; plan: IPayerPlan }
) {
	commit('removeParameterPlan', { payerId, plan })
}

function removeParameterProcedure(
	{ commit }: ActionContext<IWorklistState, any>,
	index: number
) {
	commit('removeParameterProcedure', index)
}

function setName({ commit }: ActionContext<IWorklistState, any>, name: string) {
	commit('setName', name)
}

function resetPayers({ commit }: ActionContext<IWorklistState, any>) {
	commit('resetPayers')
}

export default {
	transition: transition.bind(null, wizardMachine),
	reset,
	createWorklist,
	getAllPayers,
	resetPayers,
	addParameterProcedures,
	addParameterPayers,
	setInclude,
	setName,
	removeParameterPayer,
	removeParameterPlan,
	removeParameterProcedure,
}
