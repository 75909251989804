import { IPermission } from '@myndshft/types-mc-api'
import axios from 'axios'

import { getBff } from './shared.http'

export async function fetchPermissions(): Promise<{ roles: IPermission[] }> {
	const path = `${getBff()}/appUser`
	const response = await axios.get<{ roles: IPermission[] }>(path)
	return response.data
}
