import {
	ActiveStatus,
	ContactMethodType,
	FaxPriority,
	IPermission,
	IResponseFaxMethod,
	IResponsePhoneMethod,
	IResponsePortalMethod,
} from '@myndshft/types-mc-api'
import { IItemOutcome } from './shared'

export enum WorklistStatus {
	READY = 'READY',
	NOT_READY = 'NOT_READY',
	IN_PROGRESS = 'IN_PROGRESS',
}

export const WorklistStatusName = new Map<WorklistStatus, string>([
	[WorklistStatus.READY, 'READY'],
	[WorklistStatus.NOT_READY, 'NOT READY'],
	[WorklistStatus.IN_PROGRESS, 'IN PROGRESS'],
])

export enum WorklistItemStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	IN_REVIEW = 'IN_REVIEW',
	PENDING_PUBLISH = 'PENDING_PUBLISH',
	FAILED_PUBLISH = 'FAILED_PUBLISH',
}

export const WorklistItemStatusName = new Map<WorklistItemStatus, string>([
	[WorklistItemStatus.IN_PROGRESS, 'In Progress'],
	[WorklistItemStatus.IN_REVIEW, 'In Review'],
	[WorklistItemStatus.PENDING_PUBLISH, 'Pending Publish'],
	[WorklistItemStatus.FAILED_PUBLISH, 'Failed Publish'],
])

export enum WorklistInclude {
	EVERYTHING = 'EVERYTHING',
	NEW_ONLY = 'NEW_ONLY',
	ACTIVE_ONLY = 'ACTIVE_ONLY',
}

export interface IWorklistsItem {
	name: string
	assigneeId: string
}

export interface IWorklistCreate extends IWorklistsItem {
	parameters: IWorklistParameters
}

export interface IWorklistParameters {
	include: WorklistInclude
	payers: IWorklistPayer[]
	procedureCodes: IWorklistProcedureCode[]
}

export interface IPayerData {
	payers: IWorklistPayer[]
	totalPages: number
	total: number
}

export interface IWorklistPayer {
	hasPaRecords: boolean
	id: string
	name: string
	paPlans: IWorklistPayerPlan[]
	plans: IWorklistPayerPlan[]
	selected?: boolean
	uniqueId: string
}

export interface IWorklistPayerPlan {
	id: string
	name: string
}

export interface IWorklistProcedureCode {
	code: string
	category?: ICategory
	description: string
	selected?: boolean
	type: string
}

export interface ICategory {
	id: string
	name: string
}

export interface IProcedureCodeData {
	procedureCodes: IWorklistProcedureCode[]
	totalPages: number
	total: number
}

export interface IWorklistsMeta {
	id: string
	name?: string
	datetime: string
	displayDatetime: string
}

// Worklist collection items
export interface IWorklists {
	total: number
	totalPages: number
	worklists: IWorklist[]
}

export interface IWorklist {
	id: string
	name: string
	status: WorklistStatus
	count: number
	inProgressCount: number
	inReviewCount: number
	failedPublishCount: number
	assigneeId: string
	meta: IWorklistsMeta
}

export interface IWorklistConflicts {
	worklistId: string
	conflicts: Array<IWorklistConflictsItem>
	createdAt: string
	createdBy: string
	nextPageToken?: string | null
}

export interface IWorklistConflictsItem {
	payer: string
	plan: string
	procedure: string
	reason: string
}

export interface IWorklistFilterCode {
	code: string
	shortDescription: string
}

export interface IWorklistFilterData {
	payers: IWorklistBasicPayer[]
	plans: IWorklistBasicPlan[]
	statuses?: WorklistItemStatus[]
	codes: IWorklistFilterCode[]
}

export interface IWorklistBasicPayer {
	id: string
	name: string
}

export interface IWorklistBasicPlan {
	id: string
	type: string
}

export interface ICategoryData {
	categories: IProcedureCategory[]
	totalPages: number
	total: number
}

export interface IProcedureCategory {
	name: string
	id: string
}

export interface IWorklistItemsData {
	items: IWorklistItem[]
	total: number
	totalPages: number
}

export interface IWorklistItemData {
	item: IWorklistItem
}

export interface IWorklistItem {
	active: ActiveStatus
	id: string
	payer: IWorklistItemPayer
	plan: IWorklistItemPlan
	procedureCode: string
	shortDescription?: string
	procedureCategory: string
	outcome: IItemOutcome
	status: WorklistItemStatus
	requirementSet: {
		id: string
	} | null
	summary: IWorklistItemSummary
	meta: {
		lastModified: string
		lastModifiedBy: string
	}
}

export interface IWorklistItemSummary {
	meetsRequirements: boolean
}

export interface IWorklistItemPayer {
	id: string
	name: string
}

export interface IWorklistItemPlan {
	id: string
	type: string
}

export interface IWorklistUser {
	assigneeId: string
	permissions: IPermission[]
}

export interface IContactMethods {
	phone: IResponsePhoneMethod | null
	faxes: IResponseFaxMethod[]
	portal: IResponsePortalMethod | null
}

export interface IWorklistItemFilter {
	payerId?: string | null
	planId?: string | null
	procedureCategory?: string | null
	code?: string | null
	status?: WorklistItemStatus | null
}

export interface IWorklistContactMethod {
	id: string
	name: string
	planId: string
	type: ContactMethodType
}

export interface IContactPhoneMethod extends IWorklistContactMethod {
	number: string
}

export interface IContactFaxMethod extends IWorklistContactMethod {
	number: string
	priority: FaxPriority
}

export interface IContactPortalMethod extends IWorklistContactMethod {
	url: string
}

export interface IDisplayUser {
	id: string
	name: string
}

export interface IConfiguration {
	id: string
	name: string
}
