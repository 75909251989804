import { Ref, ref, computed } from 'vue'
import { TableSortDirection } from '../types'

/**
 * 'onclick' event handler that sets the new actively sorted column and its
 * direction (ascending or descending) based on the current configuration and
 * the index of the column that was clicked.
 */
function _updateSortColumn(
	sortColumnIndex: Ref<number>,
	sortDirection: Ref<TableSortDirection>,
	columnIndex: number
): void {
	if (sortColumnIndex.value === columnIndex) {
		// Toggle the sort direction.
		sortDirection.value =
			sortDirection.value === TableSortDirection.ASC
				? TableSortDirection.DESC
				: TableSortDirection.ASC
	} else {
		// Select new column and reset sort direction.
		sortDirection.value = TableSortDirection.ASC
		sortColumnIndex.value = columnIndex
	}
}

/**
 * Sort comparison function that defines the sort order for any two elements of
 * a type, using the pre-defined relational operators.
 */
function defaultSort(e1: any, e2: any): number {
	if (typeof e1 === 'string' && typeof e2 === 'string') {
		e1 = e1.toLowerCase()
		e2 = e2.toLowerCase()
	}
	if (e1 < e2) {
		return -1
	} else if (e1 > e2) {
		return 1
	}
	return 0
}

/**
 * Sorts an array of table data given a configuration of columns, the column
 * being sorted, and the sorting direction.
 */
function sortData(
	data: Array<any>,
	columns: Array<any>,
	sortColumnIndex: Ref<number>,
	sortDirection: Ref<TableSortDirection>
) {
	const column = columns[sortColumnIndex.value]
	const { field } = column

	data.sort((row1: any, row2: any) => {
		let relation
		if (typeof column.sortBy === 'function') {
			relation = column.sortBy(row1[field], row2[field])
		} else {
			relation = defaultSort(row1[field], row2[field])
		}

		// Invert sorting if direction is 'DESCENDING'.
		return sortDirection.value === TableSortDirection.DESC
			? -relation
			: relation
	})
}

/**
 * Perform a nested index lookup.
 *
 * E.g. lookup(obj, 'a.b.c') <=> obj[a][b][c]
 */
function lookup(obj: { [k: string]: any }, path: string): any {
	return path.split('.').reduce((acc: any, key: string) => acc[key] ?? {}, obj)
}

/**
 * Sort object value. If there is no object provide a sortable values
 */
export function sortByObjectValue(key: string): (e1: any, e2: any) => number {
	return (e1: any, e2: any) => {
		const lastValue = 'zzzzzzzz'
		const v1 = e1 ? e1[key].toLowerCase() : lastValue
		const v2 = e2 ? e2[key].toLowerCase() : lastValue
		if (v1 < v2) {
			return -1
		} else if (v1 > v2) {
			return 1
		}
		return 0
	}
}

/**
 * Produces a sort comparison function that defines the sort order for any two
 * indexable objects based on a projected value derived from `path`.
 */
export function sortByProjection(path: string): (e1: any, e2: any) => number {
	return (e1: any, e2: any) => {
		const v1 = lookup(e1, path)
		const v2 = lookup(e2, path)

		if (v1 < v2) {
			return -1
		} else if (v1 > v2) {
			return 1
		}
		return 0
	}
}

export function useSorting(columns: Array<any>, data: Ref<Array<any>>) {
	const sortColumnIndex = ref(0)
	const sortDirection = ref<TableSortDirection>(TableSortDirection.ASC)

	const sortedData = computed(() => {
		const dataCopy = [...(data.value as Array<any>)]
		sortData(dataCopy, columns, sortColumnIndex, sortDirection)
		return dataCopy
	})

	function updateSortColumn(
		isTableSortable: boolean,
		isColumnSortable: boolean,
		columnIndex: number
	) {
		if (isTableSortable && isColumnSortable) {
			_updateSortColumn(sortColumnIndex, sortDirection, columnIndex)
		}
	}

	return {
		sortColumnIndex,
		sortDirection,
		updateSortColumn,
		sortedData,
	}
}

export const testables = {
	_updateSortColumn,
	defaultSort,
	sortData,
}
