import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import client from '../client'

export default {
	namespaced: true,
	modules: { client },
	state,
	getters,
	mutations,
	actions,
}
