import {
	IPayerListItem,
	IPayerContactMethods,
	IPayerDetails,
	IContactPayerPhone,
	IContactPayerFax,
	IContactPayerPortal,
	IPayerLastModified,
	IPayerAsset,
	IResponsePayerAsset,
	IPriorAuthRecords,
	IPriorAuthRecord,
	IItemContactMethods,
	IContactPayerMethod,
} from '../../fe/payer'
import {
	AuthRequirement,
	IAddressFE,
	TransactionTypes,
	IItemOutcome,
	ContactMethodType,
} from '../../fe/shared'
import {
	FaxPriority,
	IAddress,
	IResponseAsset,
	IResponseContactMethodsGET,
	IResponseFaxMethod,
	IResponseLastModified,
	IResponsePaRecord,
	IResponsePaRecordsGET,
	IResponsePayer,
	IResponsePayersGET,
	IResponsePhoneMethod,
	IResponsePlan,
	IResponsePortalMethod,
} from '@myndshft/types-mc-api'
import { IPayerPlan } from '../../fe/plan'
import {
	IPlanAssociation,
	IPlanAssociationStatus,
} from './../../fe/planAssociation'
import { IOutcome } from '../../bff/worklist'

export function fromResponsePayerListResponse(
	payerList: IResponsePayersGET
): IPayerListItem[] {
	return payerList.payers?.map(payer => {
		return {
			id: payer.id,
			name: payer.name,
			uniqueId: payer.unique_id,
			status: payer.status,
		}
	})
}

export function fromResponseAllPayersResponse(
	payerList: IResponsePayersGET
): IPayerListItem[] {
	return payerList.payers?.map(payer => {
		return {
			id: payer.id,
			name: payer.name,
			uniqueId: payer.unique_id,
			status: payer.status,
		}
	})
}

export function fromResponsePlan(plan: IResponsePlan): IPayerPlan {
	return {
		id: plan.id,
		name: plan.name,
		uniqueId: plan.unique_id,
	}
}

export function fromResponseAssociation(
	planAssociation: IResponsePlanAssociation
): IPlanAssociation {
	return {
		plan: {
			name: planAssociation.plan.name,
			planType: planAssociation.plan.plan_type,
		},
		payer: planAssociation.payer,
		associationId: planAssociation.association_id,
		status: planAssociation.status,
		requestId: planAssociation.request_id,
		dateAdded: planAssociation.date_added,
	}
}

export function fromResponseAddress(address: IAddress | null): IAddressFE {
	return address
		? {
				lineOne: address.street[0],
				lineTwo: address.street[1],
				city: address.city,
				state: address.state,
				zip: address.zip,
		  }
		: createDefaultAddress()
}

export function fromResponsePayer(response: IResponsePayer): IPayerDetails {
	const lastModified = new Date(response._meta.last_modified)
	return {
		id: response.id,
		name: response.name,
		uniqueId: response.unique_id,
		status: response.status,
		authRequirement: response.auth_requirement,
		address: fromResponseAddress(response?.address),
		authSubmissionOONForm: response?.auth_submission_oon_form,
		lastModified,
		lastModifiedBy: response._meta.last_modified_by,
	}
}

export function createDefaultAddress(): IAddressFE {
	return {
		city: '',
		state: '',
		lineOne: '',
		lineTwo: '',
		zip: '',
	}
}

type IResponseContactMethod =
	| IResponsePhoneMethod
	| IResponseFaxMethod
	| IResponsePortalMethod

export function fromResponseContactMethods(
	response: IResponseContactMethodsGET,
	plans: any
): IPayerContactMethods {
	const methods: (
		| IResponsePhoneMethod
		| IResponseFaxMethod
		| IResponsePortalMethod
	)[] = response.contact_methods
	const phones: IResponsePhoneMethod[] = methods.filter(
		(method: IResponseContactMethod): method is IResponsePhoneMethod =>
			method.type === ContactMethodType.PHONE
	)
	const faxes: IResponseFaxMethod[] = methods.filter(
		(method: IResponseContactMethod): method is IResponseFaxMethod =>
			method.type === ContactMethodType.FAX
	)
	const portals: IResponsePortalMethod[] = methods.filter(
		(method: IResponseContactMethod): method is IResponsePortalMethod =>
			method.type === ContactMethodType.PORTAL
	)

	return {
		phones: phones
			.map(fromResponsePhones)
			.map(phone => fromResponseContactPlans(phone, plans)),
		faxes: faxes
			.map(fromResponseFaxes)
			.map(fax => fromResponseContactPlans(fax, plans)),
		portals: portals
			.map(fromResponsePortals)
			.map(portal => fromResponseContactPlans(portal, plans)),
	}
}

function fromResponseContactPlans<R extends IContactPayerMethod>(
	method: R,
	payerPlans: IPayerPlan[]
): R {
	const plan = payerPlans.find(payerPlan => payerPlan.id === method.plan.id)

	if (plan) {
		method.plan = plan
	}
	return method
}

export function fromResponsePhones(
	phone: IResponsePhoneMethod
): IContactPayerPhone {
	return {
		id: phone.id,
		name: phone.name,
		number: phone.number,
		plan: fromResponsePlan(phone.plan),
		type: phone.type as ContactMethodType.PHONE,
		_meta: fromResponseAssetMeta(phone._meta),
	}
}

export function fromResponseFaxes(fax: IResponseFaxMethod): IContactPayerFax {
	return {
		id: fax.id,
		name: fax.name,
		number: fax.number,
		plan: fromResponsePlan(fax.plan),
		priority: fax.priority as FaxPriority,
		type: fax.type as ContactMethodType.FAX,
		_meta: fromResponseAssetMeta(fax._meta),
	}
}

export function fromResponsePortals(
	portal: IResponsePortalMethod
): IContactPayerPortal {
	return {
		id: portal.id,
		name: portal.name,
		plan: fromResponsePlan(portal.plan),
		url: portal.url,
		type: portal.type as ContactMethodType.PORTAL,
		_meta: fromResponseAssetMeta(portal._meta),
	}
}

export function fromResponseAssetMeta(
	meta: IResponseLastModified
): IPayerLastModified {
	return {
		lastModifiedBy: meta?.last_modified_by,
		lastModified: meta?.last_modified,
	}
}

export function fromResponsePayerAssets(
	response: IResponseAsset[]
): IPayerAsset[] {
	return response.map((responseAsset: IResponsePayerAsset) => {
		return {
			id: responseAsset.id,
			name: responseAsset.name,
			type: responseAsset.type,
			url: responseAsset.url,
			_meta: fromResponseAssetMeta(responseAsset._meta),
		}
	})
}

export function fromResponsePayerTransactions(response: TransactionTypes[]) {
	const transactions = [
		{ name: 'ELIGIBILITY', isActive: false },
		{ name: 'PRIOR_AUTH', isActive: false },
		{ name: 'PRIOR_AUTH_SUBMISSION', isActive: false },
		{ name: 'PHARMACY_PRIOR_AUTH_SUBMISSION', isActive: false },
		{ name: 'PATIENT_RESPONSIBILITY', isActive: false },
	]
	response.forEach(transaction => {
		const index = transactions.findIndex(
			t => t.name === TransactionTypes[transaction]
		)
		if (index >= 0) {
			transactions[index].isActive = true
		}
	})
	return transactions
}

export function fromPayerPriorAuthRecords(
	request: IResponsePaRecordsGET
): IPriorAuthRecords {
	return {
		total: request.total,
		totalPages: request.total_pages,
		items: request.items.map(fromPayerPriorAuthRecord),
	}
}

export function fromPayerPriorAuthRecord(
	procedure: IResponsePaRecord
): IPriorAuthRecord {
	return {
		active: procedure.active,
		id: procedure.id,
		plan: procedure.plan.type,
		procedureCategory: procedure.procedure_category,
		procedureCode: procedure.procedure_code,
		outcome: fromResponseOutcome(procedure.outcome),
		requirementSetId: fromPayerPriorAuthRecordRequirementSet(
			procedure.requirement_set
		),
		meta: procedure._meta
			? {
					lastModified: procedure._meta.last_modified,
					lastModifiedBy: procedure._meta.last_modified_by,
			  }
			: null,
	}
}

function fromPayerPriorAuthRecordRequirementSet(set: { id?: string } = {}) {
	return set?.id || ''
}

export function fromResponseOutcome(outcome: IOutcome): IItemOutcome {
	return {
		authRequirement: (outcome.auth_requirement as AuthRequirement) || null,
		authSubmissionFormId: outcome?.auth_submission_form_id || null,
		authSubmissionPortalId: outcome?.auth_submission_portal_id || null,
		submissionConfigurationId: outcome?.submission_configuration_id || null,
		preferredMethod: (outcome.method as ContactMethodType) || null,
		contactMethods: outcome.contact_methods
			? fromResponseItemContactMethod(outcome.contact_methods)
			: {
					phone: null,
					faxes: [],
					portal: null,
			  },
		assets: outcome.assets ? outcome.assets.map(fromResponseAsset) : [],
	}
}

export function fromResponseItemContactMethod(
	methods: Array<
		IResponsePhoneMethod | IResponseFaxMethod | IResponsePortalMethod
	>
): IItemContactMethods {
	const contactMethods: IItemContactMethods = {
		phone: null,
		faxes: [],
		portal: null,
	}

	methods.forEach(method => {
		switch (method.type) {
			case ContactMethodType.PHONE:
				contactMethods.phone = method as IResponsePhoneMethod
				break
			case ContactMethodType.FAX:
				contactMethods.faxes?.push(method as IResponseFaxMethod)
				break
			case ContactMethodType.PORTAL:
				contactMethods.portal = method as IResponsePortalMethod
		}
	})
	return contactMethods
}

export function fromResponseAsset(asset: IResponseAsset): IPayerAsset {
	return {
		id: asset.id,
		type: asset.type,
		name: asset.name,
		url: asset.url,
		_meta: fromResponseAssetMeta(asset._meta),
	}
}

export interface IResponsePlanAssociationsGET {
	next_page_token: string
	associations: IResponsePlanAssociation[]
}

export interface IResponsePlanAssociation {
	plan: {
		name: string
		plan_type: string
	}
	payer: {
		id: string
		name: string
	}
	association_id: string
	status: IPlanAssociationStatus
	request_id: string
	date_added: string
}
