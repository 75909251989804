import Vue from 'vue'
import { IEnvironment } from '@server/api/models/fe/environment/environment'

const state: IEnvironment = {
	environment: '',
	googleClientId: '',
	loaded: false,
	myndcontrol: '',
	v2Route: '',
	version: '',
	gitSha: '',
	features: [''],
	build: '',
}

const mutations = {
	load(environmentState: IEnvironment, env: IEnvironment) {
		environmentState = Object.assign(environmentState, env)
		Vue.set(environmentState, 'loaded', true)
		Vue.set(environmentState, 'version', process.env.VUE_APP_VERSION)
		Vue.set(environmentState, 'gitSha', process.env.VUE_APP_SHA)
	},
	features(environmentState: IEnvironment, features: string[]) {
		environmentState.features = features as [string]
	},
	build(environmentState: IEnvironment, build: string) {
		environmentState.build = build
	},
}

const getters = {
	environment(environmentState: IEnvironmentState): IEnvironment {
		return environmentState.environment
	},
	features(environmentState: IEnvironmentState): string[] {
		return environmentState.environment.features
	},
	build(environmentState: IEnvironmentState): string {
		return environmentState.environment.build
	},
}

export const environment = {
	namespaced: true,
	state,
	getters,
	mutations,
}

interface IEnvironmentState {
	environment: IEnvironment
}
