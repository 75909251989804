import {
	ContactMethodType,
	FaxPriority,
	IResponseAsset,
	IResponseProcedureCategory,
	IResponseProcedureCode,
	IResponseSummary,
	IResponseWorklist,
	IResponseWorklistContactMethod,
	IResponseWorklistFaxMethod,
	IResponseWorklistItem,
	IResponseWorklistItemPayer,
	IResponseWorklistItemPlan,
	IResponseWorklistMeta,
	IResponseWorklistPayer,
	IResponseWorklistPayerPlan,
	IResponseWorklistPhoneMethod,
	IResponseWorklistPortalMethod,
	IResponseWorklistUser,
	WorklistItemStatus,
	WorklistStatus,
} from '@myndshft/types-mc-api'
import {
	ICategory,
	IContactFaxMethod,
	IContactMethods,
	IContactPhoneMethod,
	IContactPortalMethod,
	IProcedureCategory,
	IWorklist,
	IWorklistBasicPayer,
	IWorklistBasicPlan,
	IWorklistItem,
	IWorklistItemPayer,
	IWorklistItemPlan,
	IWorklistItemSummary,
	IWorklistPayer,
	IWorklistProcedureCode,
	IWorklistsMeta,
	IWorklistUser,
} from '../../../../server/api/models/fe/worklist'
import { IPayerAsset } from '../../../../server/api/models/fe/payer'
import {
	fromResponseOutcome,
	fromResponsePlan,
} from '../../../../server/api/models/mappers/from-server/payer'
import { IResponsePayer } from '@/models/server-models/response-models/v2/payer'

export function fromResponsePayer(payer: IResponsePayer): IWorklistPayer {
	return {
		id: payer.id,
		name: payer.name,
		plans: payer.pa_summary.count
			? payer.pa_summary.plans.map(fromResponsePlan)
			: [],
		paPlans: payer.pa_summary.count
			? payer.pa_summary.plans.map(fromResponsePlan)
			: [],
		hasPaRecords: !!payer.pa_summary.count,
		uniqueId: payer.unique_id,
	}
}

export function fromResponseProcedureCode(
	procedure: IResponseProcedureCode
): IWorklistProcedureCode {
	return {
		code: procedure.code,
		description: procedure.description,
		type: procedure.type,
		category: {} as ICategory,
	}
}

export function fromResponseWorklist(worklist: IResponseWorklist): IWorklist {
	return {
		id: worklist.id,
		name: worklist.name,
		status: worklist.status as WorklistStatus,
		count: worklist.item_summary.total_count,
		inProgressCount: worklist.item_summary.in_progress_count,
		inReviewCount: worklist.item_summary.in_review_count,
		failedPublishCount: worklist.item_summary.failed_publish_count,
		assigneeId: worklist.assignee_id || '',
		meta: fromResponseWorklistMeta(worklist._meta),
	}
}

export function fromResponseWorklistBasicPayer(
	payer: IResponseWorklistPayer
): IWorklistBasicPayer {
	return {
		id: payer.id,
		name: payer.name,
	}
}

export function fromResponseWorklistBasicPlan(
	plan: IResponseWorklistPayerPlan
): IWorklistBasicPlan {
	return {
		id: plan.id,
		type: plan.type,
	}
}

export function fromResponseWorklistMeta(
	meta: IResponseWorklistMeta
): IWorklistsMeta {
	const modifiedByDate: Date = new Date(meta.last_modified)
	return {
		id: meta.last_modified_by,
		name: meta.last_modified_by,
		datetime: meta.last_modified,
		displayDatetime: `${modifiedByDate.toLocaleDateString(
			'en-US'
		)} at ${modifiedByDate.toLocaleTimeString('en-US')}`,
	}
}

export function fromResponseProcedureCategory(
	category: IResponseProcedureCategory
): IProcedureCategory {
	return {
		id: category.id,
		name: category.name,
	}
}

export function fromResponseWorklistItem(
	item: IResponseWorklistItem
): IWorklistItem {
	const modifiedByDate: Date = new Date(item._meta.last_modified)
	return {
		active: item.active,
		id: item.id,
		payer: fromResponseWorklistItemPayer(item.payer),
		plan: fromResponseWorklistItemPlan(item.plan),
		procedureCode: item.procedure_code,
		procedureCategory: item.procedure_category,
		outcome: fromResponseOutcome(item.outcome),
		status: item.status as WorklistItemStatus,
		requirementSet: item.requirement_set,
		summary: fromResponseWorklistItemSummary(item.summary),
		meta: {
			lastModified: `${modifiedByDate.toLocaleDateString(
				'en-US'
			)} at ${modifiedByDate.toLocaleTimeString('en-US')}`,
			lastModifiedBy: item._meta.last_modified_by,
		},
	}
}

export function fromResponseWorklistItemSummary(
	summary: IResponseSummary
): IWorklistItemSummary {
	return {
		meetsRequirements: summary.meets_requirements,
	}
}

export function fromResponseAsset(asset: IResponseAsset): IPayerAsset {
	return {
		id: asset.id,
		type: asset.type,
		name: asset.name,
		url: asset.url,
		_meta: null,
	}
}

export function fromResponseItemContactMethod(
	methods: IResponseWorklistContactMethod[]
): IContactMethods {
	const contactMethods: IContactMethods = {
		phone: null,
		faxes: [],
		portal: null,
	}

	if (methods) {
		methods.forEach((method: any) => {
			switch (method.type) {
				case ContactMethodType.PHONE:
					contactMethods.phone = method
					break
				case ContactMethodType.FAX:
					if (contactMethods.faxes !== null) {
						contactMethods.faxes.push(method)
					}
					break
				case ContactMethodType.PORTAL:
					contactMethods.portal = method
			}
		})
	}
	return contactMethods
}

export function fromResponseWorklistItemPayer(
	payer: IResponseWorklistItemPayer
): IWorklistItemPayer {
	return {
		id: payer.id,
		name: payer.name,
	}
}

export function fromResponseWorklistItemPlan(
	plan: IResponseWorklistItemPlan
): IWorklistItemPlan {
	return {
		id: plan.id,
		type: plan.type,
	}
}

export function fromResponseWorklistUser(
	user: IResponseWorklistUser
): IWorklistUser {
	return {
		assigneeId: user.name,
		permissions: user.roles,
	}
}

export function fromResponseWorklistItemPhones(
	phone: IResponseWorklistPhoneMethod
): IContactPhoneMethod {
	return {
		id: phone.id,
		name: phone.name,
		number: phone.number,
		planId: phone.plan.id,
		type: phone.type as ContactMethodType.PHONE,
	}
}

export function fromResponseWorklistItemFaxes(
	fax: IResponseWorklistFaxMethod
): IContactFaxMethod {
	return {
		id: fax.id,
		name: fax.name,
		number: fax.number,
		planId: fax.plan.id,
		priority: fax.priority as FaxPriority,
		type: fax.type as ContactMethodType.FAX,
	}
}

export function fromResponseWorklistItemPortals(
	portal: IResponseWorklistPortalMethod
): IContactPortalMethod {
	return {
		id: portal.id,
		name: portal.name,
		planId: portal.plan.id,
		url: portal.url,
		type: portal.type as ContactMethodType.PORTAL,
	}
}
