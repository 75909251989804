import { AssetType } from '@myndshft/types-mc-api'
import { IPayerLastModified } from './payer'
import { IAppUser } from './appUser'
import { IContactMethods } from './worklist'

export interface IShared {
	modifiedBy: IAppUser
	createdAt: Date
	updatedAt?: Date
	updatedAtDisplay: string
}

export interface IAsset {
	id: string
	type: AssetType
	name: string
	url: string
	_meta: IPayerLastModified | null
}

export interface IGuideline extends IAsset {
	type: AssetType.GUIDELINE
}

export enum AuthRequirement {
	REQUIRED = 'REQUIRED',
	NOT_REQUIRED = 'NOT_REQUIRED',
	NOT_COVERED = 'NOT_COVERED',
	CONTACT_PAYER = 'CONTACT_PAYER',
}

export interface IAddressFE {
	city: string
	state: string
	lineOne: string
	lineTwo: string
	zip: string
}

export enum ContactMethodType {
	PHONE = 'PHONE',
	FAX = 'FAX',
	PORTAL = 'PORTAL',
}

export enum TransactionTypes {
	ELIGIBILITY = 'ELIGIBILITY',
	PRIOR_AUTH = 'PRIOR_AUTH',
	PRIOR_AUTH_SUBMISSION = 'PRIOR_AUTH_SUBMISSION',
	PHARMACY_PRIOR_AUTH_SUBMISSION = 'PHARMACY_PRIOR_AUTH_SUBMISSION',
	PATIENT_RESPONSIBILITY = 'PATIENT_RESPONSIBILITY',
}

export const TransactionNames = new Map<TransactionTypes, string>([
	[TransactionTypes.ELIGIBILITY, 'Benefits Eligibility'],
	[TransactionTypes.PRIOR_AUTH, 'Prior Auth Requirements'],
	[TransactionTypes.PRIOR_AUTH_SUBMISSION, 'Prior Auth Submission'],
	[
		TransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION,
		'Pharmacy Prior Auth Submission',
	],
	[TransactionTypes.PATIENT_RESPONSIBILITY, 'Patient Responsibility'],
])

export interface IRequestTransactions {
	transactions: TransactionTypes[]
}

export interface IRequestClientProcedure {
	code: string
}

export interface IRequestClientPayerPricing {
	procedureCode?: string
	flatRate?: string
	percentage?: string | null
	referenceSource?: string
	shortDescription?: string
}

export enum NetworkAuthRequirement {
	NO_AUTH = 'NO_AUTH', // 'No Authorization Required',
	OON = 'OUT_OF_NETWORK', // 'Out-of-Network Authorization Required',
	OON_PA = 'OUT_OF_NETWORK_AND_PA', // 'Out-of-Network & Prior Authorization Required',
}

export const NetworkAuthRequirementName = new Map<
	NetworkAuthRequirement,
	string
>([
	[NetworkAuthRequirement.NO_AUTH, 'Not Required'],
	[NetworkAuthRequirement.OON, 'Out-of-Network Required'],
	[
		NetworkAuthRequirement.OON_PA,
		'Out-of-Network & Prior Auth Requirements Required',
	],
])

export enum ReferenceSource {
	AWP = 'AWP',
	BLUEBOOK = 'Bluebook',
	LIST = 'List',
	MAC = 'MAC',
	MEDICARE = 'Medicare',
	MEDISPAN = 'Medispan',
	REDBOOK = 'Redbook',
	RJHEALTH = 'RJ Health',
	WAC = 'WAC',
}

export interface ICPT {
	code: CptCode
	description: string
}

class Nominal<T extends string> {
	private as!: T
}

export type CptCode = string & Nominal<'CptCode'>

export enum RequirementType {
	BASIC = 'BASIC',
}

export enum FieldType {
	DIAGNOSIS = 'DIAGNOSIS',
	PATIENT_STATE = 'PATIENT_STATE',
	PROCEDURE_PLACE_OF_SERVICE = 'PROCEDURE_PLACE_OF_SERVICE',
	PROCEDURE_PRICE = 'PROCEDURE_PRICE',
	PROCEDURE_QUANTITY = 'PROCEDURE_QUANTITY',
	PROCEDURE_CODE = 'PROCEDURE_CODE',
	MATCH_EVERYTHING = 'MATCH_EVERYTHING',
	PLAN_NAME = 'PLAN_NAME',
}

export enum OutcomeType {
	PRIOR_AUTH_OUTCOME = 'PRIOR_AUTH_OUTCOME',
}

export enum RequirementSetStatus {
	DRAFT = 'DRAFT',
}

export const FieldTypeName = new Map<FieldType, string>([
	[FieldType.DIAGNOSIS, 'Diagnosis'],
	[FieldType.PATIENT_STATE, 'Patient State'],
	[FieldType.PROCEDURE_PLACE_OF_SERVICE, 'Place of Service'],
	[FieldType.PROCEDURE_PRICE, 'Price'],
	[FieldType.PROCEDURE_QUANTITY, 'Quantity'],
	[FieldType.PROCEDURE_CODE, 'Code Modifier'],
	[FieldType.MATCH_EVERYTHING, 'Match Everything'],
	[FieldType.PLAN_NAME, 'Plan Name'],
])

export enum FieldOperation {
	EQ = 'EQ',
	GT = 'GT',
	GTE = 'GTE',
	LT = 'LT',
	LTE = 'LTE',
	BETWEEN = 'BETWEEN',
}

export const FieldOperationName = new Map<FieldOperation, string>([
	[FieldOperation.EQ, 'Exactly'],
	[FieldOperation.GT, 'Greater Than'],
	[FieldOperation.GTE, 'Greater Than or Equal To'],
	[FieldOperation.LT, 'Less Than'],
	[FieldOperation.LTE, 'Less Than or Equal To'],
	[FieldOperation.BETWEEN, 'Between'],
])

export enum CopyPasteType {
	REQUIREMENT = 'REQUIREMENT',
	REQUIREMENT_SET = 'REQUIREMENT_SET',
}

export interface IDiagnosisChapter {
	name: string
	description: string
	sections: IDiagnosisSection[]
}

export interface IDiagnosisSection {
	first: string
	last: string
	description: string
	categories: IDiagnosisCategory[]
}

export interface IDiagnosisCategory {
	name: string
	description: string
}

export interface IDiagnosisSectionDisplay extends IDiagnosisSection {
	label: string
	categories: IDiagnosisCategoryDisplay[]
}

export interface IDiagnosisCategoryDisplay extends IDiagnosisCategory {
	label: string
}

export interface IServiceType {
	ID: string
	name: string
}

export interface IProcedure {
	code: string
	description: string
}

export const ContactMethodTypeName = new Map<ContactMethodType, string>([
	[ContactMethodType.PHONE, 'Phone'],
	[ContactMethodType.FAX, 'Fax'],
	[ContactMethodType.PORTAL, 'Portal'],
])

export const AuthRequirementName = new Map<AuthRequirement, string>([
	[AuthRequirement.REQUIRED, 'Required'],
	[AuthRequirement.NOT_REQUIRED, 'Not Required'],
	[AuthRequirement.NOT_COVERED, 'Not Covered'],
	[AuthRequirement.CONTACT_PAYER, 'Contact Payer'],
])

export interface ITableProp {
	isSelected: boolean
	isSaving: {
		status: boolean
		guideline: boolean
		phone: boolean
		fax: boolean
		portal: boolean
		form: boolean
		submission: boolean
		preferredMethod: boolean
		authRequirement: boolean
	}
}

export function liftToTableItem<T extends {}>(item: T): ITableProp & T {
	return {
		...item,
		isDisabled: false,
		isSelected: false,
		isSaving: {
			status: false,
			guideline: false,
			phone: false,
			fax: false,
			portal: false,
			form: false,
			submission: false,
			preferredMethod: false,
			authRequirement: false,
		},
	}
}

export interface IItemOutcome {
	authRequirement: AuthRequirement | null
	authSubmissionFormId?: string | null
	authSubmissionPortalId?: string | null
	submissionConfigurationId?: string | null
	preferredMethod: ContactMethodType | null
	contactMethods: IContactMethods
	assets: IAsset[]
}

export interface IPlaceOfService {
	id: string
	code: string
	description: string
}
