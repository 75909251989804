import { State } from 'xstate'
import { ClientWizardSchema } from './machine'
import { MachineEvent } from '@/store/utils/machine'
import { IClientListResults } from '../../../server/api/models/fe/client'

function setState(
	state: any,
	newState: State<any, MachineEvent, ClientWizardSchema>
) {
	state.state = newState.value
}

function setClientDuplicates(
	state: any,
	clientDuplicates: IClientListResults['clients']
) {
	state.duplicates = clientDuplicates
}

export default {
	setState,
	setClientDuplicates,
}
