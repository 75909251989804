import { StateMachine } from 'xstate'
import { ActionContext, Store } from 'vuex'
import { computed, toRefs } from 'vue'

export interface MachineEvent {
	type: string
	state?: object // Vuex store state
	payload?: any
}

export const NULL_EVENT: MachineEvent = { type: '' }

/**
 * An xstate transition function that is compatible with Vuex. By partially
 * applying the first argument, the function signature matches that of a Vuex
 * action.
 *
 * In terms of the abstract formulation of a Mealy machine, this function is a
 * combination of the FSM's transition function (T: S × Σ -> S) and output
 * function (G: S × Σ -> Λ) where
 *
 *     S is the finite set of states.
 *     Σ is the input alphabet.
 *     Λ is the output alphabet.
 *
 * The function signature of this function when partially applied with a
 * Machine definition is extensionally equivalent (same up to isomorphism) to:
 *
 *     transition :: M -> S × Σ -> S × Λ
 *
 *       where
 *
 *     Σ = VuexState × MachineEvent, Λ = VuexState
 *
 * In other words, given the current state, an event, and external inputs (Vuex
 * store), a state transition yields the next state and an output (updated Vuex
 * store) that is used as the external input in the next state transition.
 */
export function transition(
	machine: StateMachine<object, any, MachineEvent>,
	{ commit, state, dispatch }: ActionContext<any, any>,
	{ type, payload }: MachineEvent
) {
	// T: S × Σ -> S
	const nextState = machine.transition(state.state, { type, payload, state })
	commit('setState', nextState)

	// Execute side effects
	// G: S × Σ -> Λ
	nextState.actions.forEach((actionKey: any) => {
		dispatch(actionKey, { type, payload })
	})
}

/**
 * Given the path to a Vuex module that satisfies the following abstract data type:
 *
 *   store {
 *     state {
 *       state: xstate.State
 *     }
 *     actions {
 *       transition: (ActionContext<any, any>, MachineEvent) => void
 *     }
 *   }
 *
 * This composition function returns the current state and an async transition
 * function as an interface to the finite statechart used in the module.
 */
export function useVuexMachine(
	store: Store<any>,
	machine: StateMachine<object, any, MachineEvent>,
	machineModuleNamespace: string
) {
	const { state: stateValue } = toRefs(store.state[machineModuleNamespace])

	const namespacedTransition = (event: MachineEvent): Promise<any> =>
		store.dispatch(`${machineModuleNamespace}/transition`, event)

	const state = computed(() => {
		return machine.transition(stateValue.value, NULL_EVENT)
	})

	return {
		state,
		transition: namespacedTransition,
	}
}
