import {
	ActiveStatus,
	ClientUniqueID,
	DisplayName,
	IResponseLastModified,
	PayerUniqueID,
	UserStatus,
} from '@myndshft/types-mc-api'
import { IPayerListItem, IPayerTransaction } from './payer'
import {
	IAddressFE,
	IPlaceOfService,
	ReferenceSource,
	TransactionTypes,
} from './shared'
import { ITenantDetails } from './tenant'
import { IUserDetails } from './user'

export interface IClient {
	client: IClientDetails
	inboundFax: IInboundFax
	users: IUserDetails[]
	transactions: IClientTransaction[]
	procedures: IClientProcedure[]
	clientPayers: IClientPayers
}

export interface IClientDetails {
	id: ClientUniqueID
	name: DisplayName
	firstName: DisplayName
	lastName: DisplayName
	serviceTypeID: string
	npi: number
	crmID: string
	crmAccountName: string
	tenantId: string
	tenant?: ITenantDetails
	address: IAddressFE | null
	status: ActiveStatus
	willAutoActivate: ActiveStatus
	lastModified: Date | null
	lastModifiedBy: string | null
	transactions?: IClientTransaction[]
	federalTaxId?: string | null
	placeOfService?: IPlaceOfService | null
	placeOfServiceCode?: string | null
}

export interface IInboundFax {
	number: string
	username: string
	secret: string
}

export interface IClientInformation {
	client: Partial<IClientDetails>
	address: Partial<IAddressFE>
	inboundFax: IInboundFax
}

export interface IConsumedInboundFax<T> {
	number: string
	username: string
	secret: T | null
}

export interface IClientTransaction {
	name: string
	isActive: boolean
}

export interface IClientProcedure {
	code: string
	description: string
	type: string
}

export interface IClientPayers {
	total: number
	totalPages: number
	payers: IClientPayer[]
}

export interface IClientListResults {
	clients: IClientDetails[]
	total: number
	totalPages: number
	headers: any
}

export interface IClientProcedures {
	total: number
	totalPages: number
	procedures: IClientProcedure[]
}

export interface IClientPayer {
	clientId: ClientUniqueID
	payerId: PayerUniqueID
	name: string
	uniqueId: string
	isInNetwork: boolean
	isActive: boolean
	_meta: IResponseLastModified
	isSelected?: boolean
}

export interface IClientPayerMC extends IClientPayer {
	transactions: IPayerTransaction[]
	isSelected?: boolean
}

export interface IClientPayerListItem extends IPayerListItem {
	isActive: boolean
	isSelected?: boolean
}

export interface IClientPayerTransactions {
	disabledTransactions: TransactionTypes[]
}

export interface IClientPayerTransactionsResponse {
	clientPayer: IClientPayerTransactions
	payer: IPayerTransaction[]
	client: IClientTransaction[]
}

export enum ClientTransactionTypes {
	ELIGIBILITY = 'ELIGIBILITY',
	BATCH_ELIGIBILITY = 'BATCH_ELIGIBILITY',
	PATIENT_RESPONSIBILITY = 'PATIENT_RESPONSIBILITY',
	PRIOR_AUTH_DETERMINATION = 'PRIOR_AUTH_DETERMINATION',
	PRIOR_AUTH_SUBMISSION = 'PRIOR_AUTH_SUBMISSION',
	PBM_BENEFITS_ELIGIBILITY = 'PBM_BENEFITS_ELIGIBILITY',
	PBM_PATIENT_RESPONSIBILITY = 'PBM_PATIENT_RESPONSIBILITY',
	PHARMACY_PRIOR_AUTH_SUBMISSION = 'PHARMACY_PRIOR_AUTH_SUBMISSION',
	INSURANCE_DISCOVERY = 'INSURANCE_DISCOVERY',
}

export interface IRequestClientTransactions {
	transactions: ClientTransactionTypes[]
}

export const ClientTransactionNames = new Map<ClientTransactionTypes, string>([
	[ClientTransactionTypes.ELIGIBILITY, 'Benefits Eligibility'],
	[ClientTransactionTypes.BATCH_ELIGIBILITY, 'Batch Eligibility'],
	[ClientTransactionTypes.PATIENT_RESPONSIBILITY, 'Patient Responsibility'],
	[ClientTransactionTypes.PRIOR_AUTH_DETERMINATION, 'Prior Auth Requirements'],
	[ClientTransactionTypes.PRIOR_AUTH_SUBMISSION, 'Prior Auth Submission'],
	[
		ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION,
		'Pharmacy Prior Auth Submission',
	],
	[
		ClientTransactionTypes.PBM_PATIENT_RESPONSIBILITY,
		'PBM Patient Responsibility',
	],
	[ClientTransactionTypes.PBM_BENEFITS_ELIGIBILITY, 'PBM Benefits Eligibility'],
	[ClientTransactionTypes.INSURANCE_DISCOVERY, 'Insurance Discovery'],
])

export interface IShared {
	modifiedBy: IAppUser
	createdAt: Date
	updatedAt?: Date
	updatedAtDisplay: string
}

export interface IAppUser {
	ID: number
	name: string
}

export interface IClientPayerCPT extends IShared {
	procedureCode: string
	flatRate: number | null
	percentage: number | null
	referenceSource: ReferenceSource | null
	shortDescription: string
}

export interface IClientUser {
	id: string
	name: string
	email: string
	phone: string
	status: UserStatus
}

export interface IRequestClientPayerTransactions {
	disabled_transactions: TransactionTypes[]
}
